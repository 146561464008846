import moment from 'moment';

export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "Time",
    accessor: (row) => (row.createdOn ? moment(row.createdOn).format('h:mm a D MMM') : null),
  },
  {
    Header: "Lead Name",
    accessor: (row) => `${row.leadFirstName} ${row.leadLastName}`,
  },
  {
    Header: "School",
    accessor: "leadSchool",
  },
  {
    Header: "Class",
    accessor: "classYear",
  },
  {
    Header: "Phone",
    accessor: "leadPhoneNumber",
  },
  {
    Header: "Email",
    accessor: "leadEmail",
  },
  {
    Header: "More Info Requested",
    accessor: (row) => row.requestingMoreInfo ? "Yes" : "Not Yet",
  },
  {
    Header: "Num Visits",
    accessor: (row) => row.numVisits ? row.numVisits  : 1
  },
  {
    Header: "Listing",
    accessor: "listingTitle",
  }
];

