import {
  Divider,
  Flex,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  ButtonGroup,
  Icon,
  Button,
  IconButton
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import React, { useMemo, useState, useEffect } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";

import { CheckboxStyledUrgent } from "views/admin/dataTables/components/Checkboxurgent.js";
import { CheckboxStyledNonUrgent } from "views/admin/dataTables/components/Checkboxnonurgent.js"


export default function CheckTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [cellData, setCellData] = useState(data);

  const tableInstance = useTable(
    {
      columns,
      data: cellData,
      initialState: { pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize = 15 },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
  } = tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      borderRadius='30px'
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Table {...getTableProps()} variant='striped' color='gray.500' mb='24px'>
        <Thead align='center'>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor='#959595'
                >
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "Name") {
                    if (cell.value[1]) {
                      data = (
                        <Flex align='center'>
                          <Text color={"black"} fontSize='sm' fontWeight='700'>
                            {cell.value[0]}
                          </Text>
                        </Flex>
                      );
                    } else if (!cell.value[1]) {
                      data = (
                        <Flex align='center'>
                          <Text color={"black"} fontSize='sm' fontWeight='700'>
                            {cell.value[0]}
                          </Text>
                        </Flex>
                      );
                    }
                  } else if (cell.column.Header === "Time") {
                    data = (
                      <Flex align='center'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Time remaining") {
                    if (cell.value === "Expired" || parseInt(cell.value[0]) === 0) {
                      data = (
                        <Text color="#DE6657" fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    } else if (parseInt(cell.value[0]) !== 0 && cell.value.includes("hr")) {
                      data = (
                        <Text color="#F5B75C" fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    } else {
                      data = (
                        <Text color="#000000" fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    }
                  } else if (cell.column.Header === "Notes") {
                    data = (
                      <Text color={"black"} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {/* Pagination */}
      <Flex justifyContent='center' mt='2'>
        <ButtonGroup size='sm' isAttached variant='outline'>
          <IconButton
            icon={<Icon as={ChevronLeftIcon} />}
            onClick={previousPage}
            disabled={!canPreviousPage}
            aria-label='Previous Page'
          />
          {Array.from({ length: pageCount }, (_, index) => (
            <Button
              key={index}
              variant='outline'
              colorScheme={pageIndex === index ? '#95dd6e' : undefined}
              onClick={() => gotoPage(index)}
            >
              {index + 1}
            </Button>
          ))}
          <IconButton
            icon={<Icon as={ChevronRightIcon} />}
            onClick={nextPage}
            disabled={!canNextPage}
            aria-label='Next Page'
          />
        </ButtonGroup>
      </Flex>
    </Card>
  );
}
