export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "TITLE",
    accessor: "listingTitle",
  },
  {
    Header: "CATEGORY",
    accessor: "category",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "SUBMISSION DATE",
    accessor: "submissionDate",
  },
  {
    Header: "ELAPSED TIME",
    accessor: "submittedOn",
  },
  {
    Header: "COMMENTS",
    accessor: "comments",
  },
];

