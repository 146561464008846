// Chakra imports
import {
    Box,
    Flex,
    Icon,
    Progress,
    Text,
    useColorModeValue,
    Switch,
    FormLabel,
    FormControl,
    SimpleGrid,
    Button,
    InputRightElement,
    FormHelperText,
    FormErrorMessage,
    Alert,
    AlertIcon,
    Select
} from "@chakra-ui/react";
// Custom components
import { Formik, Form, Field } from "formik";
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import Menu from "components/menu/MainMenu";
import React from "react";
import { useRef, useState, useEffect } from 'react'
import {
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
} from "@chakra-ui/react";
// Assets
import { useChangePasswordMutation } from '../../../../data/slices/auth/authApiSlice';
import { useUpdateCrmDetailsMutation } from "../../../../data/slices/users/usersSlice";

export default function CrmCard(props) {
    const {
        email,
        crmDetails
    } = props;
    const inputBg = useColorModeValue("secondaryGray.300");
    const inputText = useColorModeValue("gray.700", "gray.100");
    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)
    const [updateCrmDetails, { isLoading: isUpdatingCrmLoading, isSuccess, isError }] = useUpdateCrmDetailsMutation()

    const [errMsg, setErrMsg] = useState('')
    const [crmValue, setCrmValue] = useState(crmDetails?.crmProvider)
    const [apiKeyValue, setApiKeyValue] = useState(crmDetails?.apiKey)

    return (
        <Card mb={{ base: "0px", lg: "18px" }} width='50%' align='center' height='500px' borderRadius='30px'>
            <Text color='black' fontSize='30' mt='10px' variant='brand'>
                CRM Integration
            </Text>
            <Flex h='10px'></Flex>
            {errMsg && (
                <Alert status='error'>
                    <AlertIcon />
                    {errMsg}
                </Alert>
            )}
            {isSuccess && (
                <Alert status='success'>
                    <AlertIcon />
                    {"CRM Details Changed Successfully!"}
                </Alert>)}
            <Formik
                initialValues={{
                    crmProvider: crmDetails?.crmProvider,
                    apiKey: crmDetails?.apiKey
                }}
                onSubmit={async (values, actions) => {
    
                        try {
                            var data = {
                                crmProvider: values.crmProvider,
                                apiKey: values.apiKey,
                                email: email
                            }
                            await updateCrmDetails(data).unwrap();
                            setErrMsg('')
                            // actions.resetForm({});
                        } catch (err) {
                            switch (err?.status) {
                                case undefined:
                                    setErrMsg('No server response, please try again later!');
                                    break;
                                case 401:
                                    setErrMsg('CRM value is incorrect!');
                                    break;
                                default:
                                    setErrMsg('Unknown server error, contact support@collegesouk.com for assistance!');
                            }
                        }
                        actions.setSubmitting(false);
                }}
            >
                {(props) => (
                    <Form >
                        <FormControl variant="floating" >

                            {/* Dropdown for Choosing CRM */}
                            <Box w='100%' mb='10px'>
                                <Box w='100%' >
                                    <Flex w='100%' justify='space-between' mb='10px'>
                                        <Text color={'black'} fontSize='l' mt='10px' align='left' ms='7px'>CRM Provider</Text>
                                    </Flex>
                                </Box>
                                <Flex w='100%' justify='space-between' align='center'>
                                    <Field name="crmProvider">
                                        {({ field }) => (
                                            <InputGroup size='md'>

                                                <Select
                                                    w='100%'
                                                    {...field}
                                                    id="crmProvider"
                                                    isRequired={true}
                                                    fontSize='sm'
                                                    isDisabled={false}
                                                    placeholder='Choose your CRM'
                                                    mb='24px'
                                                    size='lg'
                                                    variant='auth'
                                                >
                                                    <option value="HUBSPOT">HubSpot</option>
                                                    {/* <option value="MSDYNAMICS">Microsoft Dynamics</option>
                                                    <option value="SALESFORCE">Salesforce</option> */}
                                                </Select>
                                            </InputGroup>)}

                                    </Field>
                                </Flex>
                            </Box>
                            <Box w='100%' mt='10px'>
                                <Box w='100%' >
                                    <Flex w='100%' justify='space-between' mb='10px'>
                                        <Text color={'black'} fontSize='l' mt='10px' align='left' ms='7px'>Api Access Key</Text>
                                    </Flex>
                                </Box>
                                <Flex w='100%' justify='space-between' align='center'>

                                    <Field name="apiKey">
                                        {({ field }) => (
                                            <InputGroup size="md">
                                                <Input
                                                    {...field}
                                                    id="apiKey"
                                                    variant="search"
                                                    fontSize="sm"
                                                    size='lg'
                                                    bg={inputBg}
                                                    type={show ? "text" : "password"}
                                                    color={inputText}
                                                    fontWeight="500"
                                                    _placeholder={{ color: "gray.400", fontSize: "14px" }}
                                                    borderRadius={"30px"}
                                                />
                                                <InputRightElement width="9.5rem">
                                                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                                                        {show ? "Hide" : "Show"}
                                                    </Button>
                                                </InputRightElement>
                                            </InputGroup>
                                        )}
                                    </Field>
                                </Flex>

                                <Flex h='50px'></Flex>



                                <Box w='100%' mt='1px'>
                                    <Flex w='100%' align='center' justify='space-between'>
                                        <Button
                                            bg="#A3F579"
                                            w='280px'
                                            minW='200px'
                                            mt={{ base: "25px", "2l": "auto" }}
                                            color={"black"}
                                            fontWeight='1000'
                                            mx='auto'
                                            type="submit"
                                            _hover={{ bgColor: "#95dd6e" }}
                                            isLoading={props.isSubmitting}

                                        >
                                            Save Changes
                                        </Button>
                                    </Flex>
                                </Box>



                            </Box>
                        </FormControl>
                    </Form>

                )}
            </Formik>
        </Card >
    );
}
