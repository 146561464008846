// Chakra imports
// Custom components
import {
  Spinner,
  Image,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  FormErrorMessage,
  Select,
  Stack,
  InputLeftElement,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  SimpleGrid,
  Link,
  Alert,
  AlertIcon
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import Card from "components/card/Card.js";
import React, { useState, useEffect } from "react";

// Assets
import { MdUpload } from "react-icons/md";
import Dropzone from "views/admin/listing/components/Dropzone";
import MarketplaceForm from "views/admin/listing/components/MarketplaceForm";
import HousingForm from "views/admin/listing/components/HousingForm";
import ApparelForm from "views/admin/listing/components/ApparelForm";
import ServiceForm from "views/admin/listing/components/ServiceForm";
import { useAddApparelListingMutation, useAddHousingListingMutation, useAddMarketplaceListingMutation, useGetSubcategoriesMarketplaceQuery, useAddServiceListingMutation, useGetApparelCategoriesQuery, useGetServicesCategoriesQuery } from "../../../../data/slices/listings/listingsslice";
import { useGetUserQuery } from "../../../../data/slices/users/usersSlice";
import { useGetSubDetailsQuery } from "../../../../data/slices/subscription/subscription";
import { isPremium } from "../../../../util/util";
import illustrationListingCreated from "assets/img/auth/listing_created.png";
import StripeModalPopup from "../../../../components/sidebar/components/StripeModalPopup"
import { isPlanEligibleToPostToAllSchools, getMaxSchoolsEligible } from "../../../../util/util";

import { HamburgerIcon, EditIcon, Search2Icon, PhoneIcon } from '@chakra-ui/icons'
import { useHistory } from 'react-router-dom';

export default function Upload(props) {
  const { used, total, ...rest } = props;
  // Chakra Color Mode
  const [errMsg, setErrMsg] = useState('')
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [mainCategory, setMainCategory] = useState(localStorage.getItem('categoryDraft') ? localStorage.getItem('categoryDraft') : null);
  const [description, setDescription] = useState(localStorage.getItem('descriptionDraft') && (localStorage.getItem('descriptionDraft') != "null") ? localStorage.getItem('descriptionDraft') : null);
  const [postHousingListing, { isLoadingHousingListing }] = useAddHousingListingMutation();
  const [postApparelListing, { isLoadingApparelListing }] = useAddApparelListingMutation();
  const [postServiceListing, { isLoadingServiceListing }] = useAddServiceListingMutation();

  const [postMarketplaceListing, { isLoadingMarketplaceListing }] = useAddMarketplaceListingMutation();
  const { data, isLoading: isLoadingUser, isSuccess: isSuccessUser, isError: isErrorUser, error: userError } = useGetUserQuery();
  const { data: subDetails, error, isLoading } = useGetSubDetailsQuery(data?.email);
  const isPremiumAccount = isPremium(subDetails);
  const { data: subcategories, isLoading: isLoadingSubcategories } = useGetSubcategoriesMarketplaceQuery();
  const { data: apparelTypes, isLoading: isLoadingApparelTypes, isSuccess: isSuccessApparelTypes, isError: isErrorApparelTypes, error: errorApparelTypes } = useGetApparelCategoriesQuery();
  const { data: serviceCategories, isLoading: isLoadingServices, isSuccess: isSuccessService, isError: isErrorService, error: errorService } = useGetServicesCategoriesQuery();

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenPricing, onOpen: onOpenPricing, onClose: onClosePricing } = useDisclosure()
  const isPlanEligible = isPlanEligibleToPostToAllSchools(subDetails);
  const maxSchoolsListingLimit = getMaxSchoolsEligible(subDetails)
  const [selectedFiles, setSelectedFiles] = useState([]);
  const history = useHistory();

  const handleSelectedFiles = (files) => {
    setSelectedFiles(files);
  };

  function renderForm(dropdownCategory) {
    switch (dropdownCategory) {
      case "Marketplace":
        return (
          <MarketplaceForm
            handleSubmit={submitListingToApi}
            maxSchoolsListingLimit={maxSchoolsListingLimit}
            isPlanEligibleToPostToAllSchools={isPlanEligible}
            subcategories={subcategories ? subcategories : []}
          />
        );
      case "Housing Arrangements":
        return (
          <HousingForm
            handleSubmit={submitListingToApi}
            maxSchoolsListingLimit={maxSchoolsListingLimit}
            isPlanEligibleToPostToAllSchools={isPlanEligible}
            isDisabled={false}
          />
        );
      case "Apparel":
        return (
          <ApparelForm
            handleSubmit={submitListingToApi}
            maxSchoolsListingLimit={maxSchoolsListingLimit}
            isPlanEligibleToPostToAllSchools={isPlanEligible}
            apparelTypes={apparelTypes ? apparelTypes : []}
          />
        );
      case "Service":
        return (
          <ServiceForm
            handleSubmit={submitListingToApi}
            maxSchoolsListingLimit={maxSchoolsListingLimit}
            isPlanEligibleToPostToAllSchools={isPlanEligible}
            serviceCategories={serviceCategories ? serviceCategories : []}
          />
        );
      default:
        return (
          <HousingForm
            handleSubmit={submitListingToApi}
            maxSchoolsListingLimit={maxSchoolsListingLimit}
            isPlanEligibleToPostToAllSchools={isPlanEligible}
            isDisabled={!(description && mainCategory)}
          />
        );
    }
  }

  const submitListingToApi = async (formDetails, resetSelectedSchools) => {
    if (isPremiumAccount) {
      try {
        var formWithUpdates = {
          ...formDetails,
          description: description,
          ownerEmail: data?.email
        }

        const formData = new FormData();
        formData.append('listingDetails',
          new Blob([JSON.stringify(formWithUpdates)], {
            type: 'application/json'
          }));
        if (selectedFiles && selectedFiles.length > 0) {
          for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('images', selectedFiles[i]);
          }
        } else {
          formData.append('images', []);
        }


        switch (formWithUpdates?.type) {
          case undefined:
            break;
          case "Marketplace":
            const responseMarketplace = await postMarketplaceListing(formData).unwrap();
            onOpen();
            resetSelectedSchools([]);
            await localStorage.clear();
            break;
          case "Housing Arrangements":
            const responseHousing = await postHousingListing(formData).unwrap();
            onOpen();
            resetSelectedSchools([]);
            await localStorage.clear();
            break;
          case "Service":
            const responseService = await postServiceListing(formData).unwrap();
            onOpen();
            resetSelectedSchools([]);
            await localStorage.clear();
            break;
          case "Apparel":
            const responseApparel = await postApparelListing(formData).unwrap();
            onOpen();
            resetSelectedSchools([]);
            await localStorage.clear();
            break;
          default:
            break;
        }
      } catch (err) {
        console.log(err);
        console.log("error posting listing");
        switch (err?.status) {
          case undefined:
            setErrMsg('No server response, please try again later!');
            break;
          case 401:
            setErrMsg(err?.data?.message ? err?.data?.message : 'You are not eligible to make this listing.\n Upgrade your current plan!');
            break;
          default:
            setErrMsg('Unknown server error, contact support@collegesouk.com for support');
        }
      }
    } else {
      onOpenPricing();
    }
  }

  const handleDescriptionChange = async (event) => {
    setDescription(event.target.value);
    if (event.target.value) {
      await localStorage.setItem('descriptionDraft', event.target.value);
    } else {
      await localStorage.setItem('descriptionDraft', '');
    }
  };

  if (isLoading || isLoadingUser || isLoadingSubcategories || isLoadingApparelTypes || isLoadingServices) {
    return (<Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      height='100vh'
    >
      <Spinner size='xl' />
    </Box>)
  }
  return (
    <Flex >
      <StripeModalPopup
        isOpen={isOpenPricing}
        onOpen={onOpenPricing}
        onClose={onClosePricing}
      />
      {/* {renderForm} */}
      {/* {alert(mainCategory)} */}

      <Card
        borderTopLeftRadius='20px'
        borderBottomLeftRadius='20px'
        direction='column'
        px='20px'
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        {errMsg ?
          <Flex flexDirection={"column"} align='center' mb='10px'>
            <Alert status='error'>
              <AlertIcon />
              {errMsg}
            </Alert>
          </Flex> : null}
        <FormLabel
          ms='4px'
          fontSize='sm'
          fontWeight='1000'
          color={textColorPrimary}
          isRequired={true}
          display='flex'>
          Main Category<Text color={brandStars}>*</Text>
        </FormLabel>
        <InputGroup size='md' width='95%'>
          <Select
            icon={<HamburgerIcon />}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            mb='24px' // | space
            fontWeight='1000'
            size='lg'
            onChange={s => {
              setDescription(null);
              localStorage.setItem('descriptionDraft', null);

              if (s.target.value == "Select Listing Category") {
                setMainCategory(null);
              } else {
                localStorage.setItem('categoryDraft', s.target.value);
                setMainCategory(s.target.value);
              }
            }}
            defaultValue={mainCategory}
          >
            <option value={null}>Select Listing Category</option>
            <option value="Housing Arrangements">Housing Arrangements</option>
            {/* <option value="Marketplace">Marketplace</option> */}
            {/* <option value="Apparel">Apparel</option> */}
            <option value="Service">Service</option>
          </Select>
        </InputGroup>

        <FormLabel
          ms='4px'
          fontSize='sm'
          fontWeight='100'
          color={textColorPrimary}
          isRequired={false}
          display='flex'>
          Additional Details
        </FormLabel>

        <InputGroup size='md' h='180px' width='95%'>
          <Textarea
            isRequired={false}
            variant='auth'
            fontSize='sm'
            isDisabled={mainCategory == null}
            ms={{ base: "0px", md: "0px" }}
            placeholder='Give more details about your listing'
            mb='12px'
            fontWeight='500'
            size='lg'
            rows={3}
            cols={86}
            defaultValue={description && description != 'null' ? description : ''}
            onChange={handleDescriptionChange}
            style={{
              border: '1px solid #cbd5e0',
              padding: '10px',
              borderRadius: '15px',
              resize: 'none'
            }}
          />
        </InputGroup>
        <Flex h='40%' direction={{ base: "row", "2xl": "row" }}>

          <Dropzone
            width='400px'
            me='10px'
            mt={8}
            maxH={{ base: "80%", lg: "80%", "2xl": "100%" }}
            minH={{ base: "80%", lg: "80%", "2xl": "100%" }}
            onSelectedFiles={handleSelectedFiles}
            content={
              <Box>
                <Icon as={MdUpload} w='80px' h='80px' color={brandColor} />
                <Flex justify='center' mx='auto' mb='12px'>
                  <Text fontSize='xl' fontWeight='700' color={brandColor}>
                    Upload Files
                  </Text>
                </Flex>
                <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                  PNG and JPG files are allowed
                </Text>
              </Box>
            }
          />
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent
              borderRadius="20px"
              width={{ base: "90%", sm: "80%", md: "60%" }}
            >
              <ModalBody>
                <Box align="center" mb="20px">
                  <ModalHeader>Listing Created!</ModalHeader>
                </Box>
                <Box
                  align="center"
                  display="flex"
                  justifyContent="center"
                  mb="20px"
                >
                  <Image src={illustrationListingCreated} />
                </Box>
                <Box align="center" mb="20px" mr="30px">
                  <ModalFooter>
                    Go to&nbsp;
                    <Link
                      href="#"
                      fontWeight="bold"
                      textDecoration="underline"
                      cursor="pointer"
                      color="black"
                      _hover={{ color: '#95dd6e' }}
                      onClick={() => history.push('/admin/listings')}
                    >
                      Listings
                    </Link>&nbsp;to manage your listings
                  </ModalFooter>
                </Box>

              </ModalBody>

            </ModalContent>

          </Modal>
        </Flex>
      </Card>
      {renderForm(mainCategory)}
    </Flex>
  );
}
