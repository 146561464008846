// Chakra imports
import { Formik, Form, Field } from 'formik';
import {
  Button,
  Box,
  Flex,
  Icon,
  Progress,
  Text,
  useColorModeValue,
  Switch,
  FormLabel,
  FormControl,
  SimpleGrid,
  Stack,
  Spinner
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import Menu from "components/menu/MainMenu";
import React from "react";
import { useRef, useState, useEffect } from 'react'
import { useVerifyUserEmailMutation, useMarkUserEmailVerifiedMutation, useVerifyUserPhoneMutation, useMarkUserPhoneVerifiedMutation } from "../../../../data/slices/users/usersSlice";

// Assets
import { MdCheckCircle, MdCreditCard, MdOutlineError, MdVisibility, MdOutlineTimer } from "react-icons/md";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Alert,
  AlertIcon
} from "@chakra-ui/react";
export default function Banner(props) {
  const { phone, email, isEmailVerified, isPhoneVerified } = props;
  const [phoneInput, setPhoneInput] = useState(phone ? phone : '')
  const [emailVerified, setEmailVerified] = useState(isEmailVerified)
  const [phoneVerified, setPhoneVerified] = useState(isPhoneVerified)
  const [phoneNumber, setPhoneNumber] = useState(phone)

  // Chakra Color Mode
  const inputBg = useColorModeValue("secondaryGray.300");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const [isEmailConfirmationPopup, setIsEmailConfirmationPopup] = useState(false);
  const [emailCodeConfirmation, setEmailCodeConfirmation] = useState(null);
  const [phoneCodeConfirmation, setPhoneCodeConfirmation] = useState(null);
  const [codeInput, setCodeInput] = useState(null);

  const [errMsg, setErrMsg] = useState('')
  const [codeErrMsg, setCodeErrMsg] = useState('')
  const [verifyUserEmail, { isLoading: isSendingUserVerificationEmail }] = useVerifyUserEmailMutation()
  const [verifyPhoneNumber, { isLoading: isSendingPhoneVerification }] = useVerifyUserPhoneMutation()
  const [markUserEmail, { isLoading: isMarkingEmailVerified }] = useMarkUserEmailVerifiedMutation()
  const [markUserPhone, { isLoading: isMarkingPhoneVerified }] = useMarkUserPhoneVerifiedMutation()

  const { isOpen, onOpen, onClose } = useDisclosure()


  const handleVerifyCode = async () => {
    try {
      if (phoneInput && phoneInput.length === 10) {
        var body = {
          email: email,
          phoneNumber: phoneInput
        }
        onOpen();
        const result = await verifyPhoneNumber(body).unwrap();
        setCodeInput(result.accessCode);
        setIsEmailConfirmationPopup(false);
        setErrMsg('')
      } else {
        setErrMsg('Phone number must be 10 digits');
      }
    } catch (err) {
      console.log(err);
      setErrMsg('Unknown error');
    }
  }

  const handleVerifyEmailCode = async () => {
    try {
      onOpen();
      const result = await verifyUserEmail(email).unwrap();
      setCodeInput(result.accessCode);
      setIsEmailConfirmationPopup(true);
    } catch (err) {
      console.log(err);
      setErrMsg('Unknown server error');
    }
  }
  const handleSubmitEmailCode = async () => {
    try {
      if ((emailCodeConfirmation == codeInput)) {
        setEmailVerified(true)
        await markUserEmail(email)
        setCodeErrMsg('')
        onClose()
        setCodeInput('');
      } else {
        setEmailVerified(false)
        // setPhoneVerified(true)
        setCodeErrMsg('Invalid Code!')
      }
    } catch (err) {
      setCodeInput('');
      setErrMsg('Unknown server error, failed email verification');
      onClose();
    }
  }
  const handleSubmitPhoneCode = async () => {
    try {
      if ((phoneCodeConfirmation == codeInput)) {
        setPhoneVerified(true)
        setPhoneNumber(phoneInput)
        await markUserPhone({ email: email, phoneNumber: phoneInput })
        setCodeErrMsg('')
        setCodeInput('');
        onClose()
      } else {
        setPhoneVerified(false)
        setCodeErrMsg('Invalid Code!')
      }
    } catch (err) {
      console.log(err);
      setErrMsg('Unknown server error, failed phone verification');
      onClose();
      setCodeInput('');
      setPhoneVerified(false)
    }
  }

  return (
    <Card mb={{ base: "0px", lg: "18px" }} width='95%' align='center' height='92%' borderRadius='30px'>
      {errMsg && (
        <Alert status='error'>
          <AlertIcon />
          {errMsg}
        </Alert>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        {!(isSendingUserVerificationEmail || isSendingPhoneVerification || isMarkingEmailVerified || isMarkingPhoneVerified) ?
          <ModalContent
            borderRadius="20px"
            width={{ base: "90%", sm: "80%", md: "60%" }}
          >
            {codeErrMsg && (
              <Alert status='error'>
                <AlertIcon />
                {codeErrMsg}
              </Alert>
            )}

            <ModalBody>

              <Box align="center" mb="20px">
                <ModalHeader>{`We have sent a 5 digit code to ${isEmailConfirmationPopup ? email : ('+1' + phoneInput)}`}</ModalHeader>
              </Box>
              <Box
                align="center"
                display="flex"
                justifyContent="center"
                mb="20px"
              >
                <Input
                  width='20%'
                  h='15%'
                  maxLength={5}
                  variant='search'
                  fontSize='sm'
                  bg={inputBg}
                  type={'tel'}
                  value={isEmailConfirmationPopup ? emailCodeConfirmation : phoneCodeConfirmation}
                  onChange={(event) => isEmailConfirmationPopup ? setEmailCodeConfirmation(event.target.value) : setPhoneCodeConfirmation(event.target.value)}
                  color={inputText}
                  fontWeight='500'
                  _placeholder={{
                    color: "gray.400",
                    fontSize: "14px",
                    textAlign: 'center',
                  }}
                  borderRadius={"10px"}
                  placeholder='56ZO9'
                />
              </Box>

              <Box
                align="center"
                display="flex"
                justifyContent="center"
                mb="20px"
              >
                <Button
                  bg="#A3F579"
                  // w='280px'
                  // minW='200px'
                  // mt={{ base: "25px", "2l": "auto" }}
                  color={"black"}
                  fontWeight='1000'
                  mx='auto'
                  type="submit"
                  _hover={{ bgColor: "#95dd6e" }}
                  onClick={isEmailConfirmationPopup ? handleSubmitEmailCode : handleSubmitPhoneCode}
                >
                  Verify
                </Button>
              </Box>


            </ModalBody>

          </ModalContent> :

          <ModalContent
            borderRadius="20px"
            width={{ base: "90%", sm: "80%", md: "60%" }}
          >
            <ModalBody>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                height='20vh'
              >
                <Spinner size='xl' />
              </Box>
            </ModalBody>
          </ModalContent>
        }

      </Modal>
      <Text color='black' fontSize='30' mt='10px' variant='brand'>
        Contact info
      </Text>
      <Box w='100%' mt='10px'>
        <Flex w='90%' justify='space-between' mb='10px' >
          <Flex align='center'>
            <Text color={'black'} fontSize='22' fontWeight='semibold' mt='5px' align='left' >
              Phone number
            </Text>
          </Flex>
          {/* <Text color={'gray'} fontSize='l' mt='5px' align='left' >
            Edit
          </Text> */}
        </Flex>
        <Input
          width='90%'
          h='15%'
          maxLength={10}
          variant='search'
          fontSize='sm'
          bg={inputBg}
          type={'tel'}
          value={phoneInput}
          onChange={(event) => setPhoneInput(event.target.value)}
          color={inputText}
          fontWeight='500'
          _placeholder={{ color: "gray.400", fontSize: "14px" }}
          borderRadius={"10px"}
          placeholder='(555) 555-5555'
        />
        <Flex w='90%' justify='space-between' mb='10px'>
          <Flex align='center'>
            <Text color={phoneVerified && (phoneNumber === phoneInput) ? 'green' : 'red'} fontSize='l' mt='5px' align='left' >
              {phoneVerified && (phoneNumber === phoneInput) ? 'Verified' : 'Not Verified'}
            </Text>
          </Flex>
          {(phoneNumber !== phoneInput && phoneInput && phoneInput.length === 10)  ? (
            <Button
              mt='5px'
              fontSize='l'
              color="black"
              fontWeight='500'
              borderRadius={"10px"}
              _hover={{ bg: "#95dd6e" }}
              onClick={handleVerifyCode}
            >
              Verify
            </Button>
          ) : null}

        </Flex>
        <Flex w='90%' justify='space-between' mb='10px'>
          <Flex align='center'>
            <Text color={'black'} fontSize='22' fontWeight='semibold' mt='5px' align='left' >
              Email address
            </Text>
          </Flex>
          {/* <Text color={'gray'} fontSize='l' mt='5px' align='left' >
            Edit
          </Text> */}
        </Flex>
        <Flex h='8px'></Flex>
        <Input
          h='15%'
          placeholder='al@collegesouk.com'
          width='90%'
          max={10}
          value={email}
          variant='search'
          fontSize='sm'
          bg={inputBg}
          type={'email'}
          color={"black"}
          fontWeight='500'
          _placeholder={{ color: "black", fontSize: "16px" }}
          borderRadius={"10px"}
        />
        <Flex w='90%' justify='space-between' mb='10px'>
          <Flex align='center'>
            <Text color={emailVerified ? 'green' : 'red'} fontSize='l' mt='5px' align='left' >
              {emailVerified ? 'Verified' : 'Not Verified'}
            </Text>
          </Flex>
          {!emailVerified && (
            <Button
              mt='5px'
              fontSize='l'
              color="black"
              fontWeight='500'
              borderRadius={"10px"}
              _hover={{ bg: "#95dd6e" }}
              onClick={handleVerifyEmailCode}
            >
              Verify
            </Button>)}
        </Flex>
      </Box>
    </Card >
  );
}
