import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie';

export const apiSlice = createApi({
    // reducerPath: 'api', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_DASH_API_BASEURL,
        credentials: 'include',
        prepareHeaders: (headers, { getState }) => {
            const token = Cookies.get('SOUK_TOKEN');
            // const token = getState().auth.token
            if (token) {
                headers.set("Authorization", `Bearer ${token}`)
            }
            return headers
        }
    }),
    tagTypes: ['user'],
    endpoints: builder => ({})
})

