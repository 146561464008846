import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  FormErrorMessage,
  Divider,
  Alert,
  AlertIcon
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import Cookies from 'js-cookie';
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/gr-banner.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useRef, useState, useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCredentials } from '../../../data/slices/auth/authSlice'
import { useLoginMutation, useGoogleLoginMutation } from '../../../data/slices/auth/authApiSlice'
import { usersApiSlice } from '../../../data/slices/users/usersSlice';
import { subApiSlice } from '../../../data/slices/subscription/subscription';
import { setAuthenticated } from "../../../data/slices/auth/authSlice";
import { useGoogleLogin } from '@react-oauth/google';

function SignIn(props) {

  const history = useHistory();
  const [errMsg, setErrMsg] = useState('')
  const [login, { isLoading }] = useLoginMutation();
  const [loginWithGoogle, { isLoading: isLoadingGoogleLogin }] = useGoogleLoginMutation();

  const dispatch = useDispatch()
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => handleAccessTokenResponse(tokenResponse?.access_token),
  });

  const handleAccessTokenResponse = async (token) => {
    try {
      await loginWithGoogle(token).unwrap()
      await dispatch(setAuthenticated(true));
      history.push("/admin/default");
    } catch (err) {
      console.log(err);
      switch (err?.status) {
        case undefined:
          setErrMsg('No server response, please try again later!');
          break;
        case 404:
          setErrMsg('Account does not exist, please register and try again.');
          break;
        case 403:
          setErrMsg('Incorrect password.');
          break;
        case 401:
          setErrMsg('Unauthorized!');
          break;
        default:
          setErrMsg('Unknown server error, contact support@collegesouk.com for assistance!');
      }
    }
  }

  const handleSubmit = async (values) => {
    try {
      await login({
        email: values.email,
        password: values.password
      }).unwrap()
      await dispatch(setAuthenticated(true));
      history.push("/admin/default");
    } catch (err) {
      console.log(err);
      switch (err?.status) {
        case undefined:
          setErrMsg('No server response, please try again later!');
          break;
        case 404:
          setErrMsg('Account does not exist, please register and try again.');
          break;
        case 403:
          setErrMsg('Incorrect password.');
          break;
        case 401:
          setErrMsg('Unauthorized!');
          break;
        default:
          setErrMsg('Unknown server error, contact support@collegesouk.com for assistance!');
      }
    }

    // if (!values.rememberMe) {
    //   Cookies.remove("SOUK_TOKEN");
    // }
  }
  return (
    <DefaultAuth isSignIn={true} illustrationBackground={illustration} align='center' >
      <Box bg='#141d28' p={[10]} mt={[1000, '2vh']} direction='column'
        w='500px'
        h='700px'
        maxW='100%'
        borderRadius='15px'
      >
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w='100%'
          mx={{ base: "auto", lg: "0px" }}
          me='auto'
          h='100%'
          alignItems='start'
          justifyContent='center'
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "2vh" }}
          flexDirection='column'>
          <Box me='auto'>
            <Heading color='white' fontSize='36px' mb='10px' fontFamily={'arial'} fontWeight='light'>
              Sign in
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color="white"
              fontWeight='400'
              fontSize='md'>
              Enter your email and password to sign in!
            </Text>
          </Box>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "10px", md: "auto" }}>
            <Button
              fontSize='md'
              me='0px'
              mb='26px'
              py='15px'
              h='50px'
              borderRadius='16px'
              bg='white'
              color={googleText}
              fontWeight='500'
              _hover={googleHover}
              _active={googleActive}
              onClick={() => googleLogin()}
              _focus={googleActive}>
              <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
              Continue with Google
            </Button>
            <Flex align='center' mb='25px'>
              <Divider borderColor={'white'} />
              <Text color='white' mx='14px'>
                or
              </Text>
              <Divider borderColor={'white'} />
            </Flex>

            {errMsg ?
              <Flex align='center' mb='25px'>
                <Alert status='error'>
                  <AlertIcon />
                  {errMsg}
                </Alert>
              </Flex> : null}
            <Formik
              initialValues={{ email: null, password: null, rememberMe: true }}
              onSubmit={async (values, actions) => await handleSubmit(values)}
            >
              {(props) => (
                <Form>
                  <Field name="email" >
                    {/* Trouble in this line below*/}
                    {({ field, form }) => (
                      <FormControl
                      >
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='md'
                          fontFamily={'arial'}
                          fontWeight='light'
                          color='white'
                          mb='2px'>
                          Email<Text color='white'></Text>
                        </FormLabel>
                        <Input
                          {...field} id="email"
                          placeholder="Email"
                          isRequired={true}
                          variant='auth'
                          fontSize='sm'
                          ms={{ base: "0px", md: "0px" }}
                          type='email'
                          placeholded='mail@collegesouk.com'
                          mb='24px'
                          fontWeight='500'
                          size='lg'
                          bg='white'
                        />
                        <FormErrorMessage>
                          {form.errors.email}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="password" >
                    {/* Trouble in this line below*/}
                    {({ field, form }) => (
                      <FormControl
                      >
                        <FormLabel
                          ms='4px'
                          fontSize='md'
                          fontFamily={'arial'}
                          fontWeight='light'
                          color='white'
                          isRequired={true}
                          display='flex'
                          mb='2px'>
                          Password<Text color='white'></Text>
                        </FormLabel>
                        <InputGroup size='md'>
                          <Input
                            {...field} id="password"
                            placeholder="Password"
                            isRequired={true}
                            fontSize='sm'
                            placeholde='Min. 8 characters'
                            mb='24px'
                            size='lg'
                            type={show ? "text" : "password"}
                            variant='auth'
                            bg='white'
                          />
                          <InputRightElement display='flex' alignItems='center' mt='4px'>
                            <Icon
                              color={textColorSecondary}
                              _hover={{ cursor: "pointer" }}
                              as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                              onClick={handleClick}
                            />
                          </InputRightElement>


                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.password}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Flex justifyContent='space-between' align='center' mb='24px'>
                    <Field name="rememberMe" >
                      {/* Trouble in this line below*/}
                      {({ field, form }) => (
                        <FormControl display='flex' alignItems='center'>
                          <Checkbox
                            {...field}
                            defaultChecked={field}
                            borderColor="#95dd6e"
                            iconColor="#95dd6e"
                            _hover={{ bgColor: "#95dd6e" }}
                            id='rememberMe'
                            colorScheme='#95dd6e'
                            me='10px'
                          />
                          <FormLabel
                            htmlFor='remember-login'
                            mb='0'
                            fontWeight='normal'
                            color='white'

                            fontSize='sm'>
                            Keep me logged in
                          </FormLabel>
                        </FormControl>)}
                    </Field>
                    <NavLink to='/auth/forgot-password'>
                      <Text
                        fontSize='sm'
                        w='124px'
                        fontWeight='500'
                        _hover={{
                          textDecoration: 'underline',
                          color: '#95dd6e',
                        }}
                        color='white'>
                        Forgot password?
                      </Text>
                    </NavLink>
                  </Flex>
                  <Flex justifyContent='space-between' align='center' mb='24px'>

                    <Button
                      fontSize='lg'
                      fontWeight='semibold'
                      variant='brand'
                      color='black'
                      w='250px'
                      h='50'
                      mb='24px'
                      isLoading={props.isSubmitting || isLoadingGoogleLogin}
                      type="submit"
                      background='#A3F579'
                      ms='80px'
                      boxShadow='0px 4px 4px rgba(0, 0, 0, 0.15)'
                      borderRadius='50px'

                      // on actions
                      _hover={{ background: '#95dd6e' }}>
                      Go!
                    </Button>
                  </Flex>

                </Form>
              )}
            </Formik>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='start'
              maxW='100%'
              top='35%'
              mt='0px'
              mb='37px'
            >
              <Text color='white' fontWeight='400' fontSize='14px'>
                Not registered yet?
                <NavLink to='/auth/sign-up'>
                  <Text
                    color='#a3f579'
                    as='span'
                    ms='5px'
                    fontWeight='500'>
                    Create an Account
                  </Text>
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Box>

    </DefaultAuth >
  );
}

export default SignIn;
