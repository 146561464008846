import { Stack, Image, Button, Flex, Input, useColorModeValue, SimpleGrid, Box, Alert, AlertIcon } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { FaTimes } from 'react-icons/fa';

function Dropzone(props) {
  const { content, onSelectedFiles, existingImages, ...rest } = props;
  const maxFileSizeAllowed = process.env.REACT_APP_MAX_FILE_SIZE;

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ accept: 'image/*', multiple: true, maxFiles: 6, maxSize: maxFileSizeAllowed }); // added maxSize option
  const bg = useColorModeValue("gray.100", "navy.700");
  const [images, setImages] = useState(existingImages ? existingImages : []);
  const [error, setError] = useState(null); // added error state

  useEffect(() => {
    const newImages = [
      ...images,
      ...acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
    ];
    setImages(newImages);
    onSelectedFiles && onSelectedFiles(newImages);
  }, [acceptedFiles]);

  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");

  const handleRemove = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
    onSelectedFiles && onSelectedFiles(newImages);
  }

  const handleDropRejected = (rejectedFiles, evt) => {

    const fileSizeExceedsLimit = rejectedFiles.some(file => file.size > maxFileSizeAllowed);
    if (fileSizeExceedsLimit) {
      setError("File size must be less than 10MB");
    } else {
      setError(null);
    }
  };

  return (
    <Flex flexDirection='column' >
      {error && <Box color="red.500">{error}</Box>} {/* added error message */}
      {images.length < 6 && (
        <Flex
          align='center'
          justify='center'
          bg={bg}
          border='1px dashed'
          borderColor={borderColor}
          borderRadius='16px'
          w='100%'
          h='175px'
          minH='100%'
          cursor='pointer'
          {...getRootProps({ className: "dropzone" })}
          onDropRejected={handleDropRejected} /* added onDropRejected handler */
          {...rest}
        >
          <Input variant='main' {...getInputProps()} />
          <Button variant='no-effects'>{content}</Button>
        </Flex>)}
      {error && (
        <Alert status='error'>
          <AlertIcon />
          {error}
        </Alert>
      )}
      {images.length > 0 && (
        <Flex flexDirection='column' >

          <SimpleGrid
            marginTop={5}
            columns={[2, null, 3]}
            gap='10px'
            mb='20px'
            width='90%'
            ms='20px'>
            {images.map((file, index) => (
              <Box key={index} position='relative'>
                <Image
                  objectFit='fill'
                  src={file.preview ? file.preview : file}
                  fontWeight='bold'
                  variant='solid'
                  boxSize='120px'
                  ms='auto'
                  display='flex'
                  borderRadius='8px'
                  alignItems='center'
                  justifyContent='center'
                />
                <Flex
                  position='absolute'
                  top={0}
                  right={0}
                  color='#95dd6e'
                  p={1}
                  onClick={() => handleRemove(index)}
                >
                  <FaTimes />
                </Flex>
              </Box>
            ))}
          </SimpleGrid>
        </Flex>
      )}
    </Flex>
  );
}


export default Dropzone;
