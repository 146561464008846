import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from './api/apiSlice';
import { subSlice } from './api/subSlice';
import authReducer from './slices/auth/authSlice';

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        [subSlice.reducerPath]: subSlice.reducer,
        auth: authReducer, // Add the authSlice reducer here
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware, subSlice.middleware),
    devTools: true
});
