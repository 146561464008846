/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Spinner,
  FormErrorMessage,
  InputGroup,
  Textarea
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";

import { Box, Flex, Button, Text, SimpleGrid, useDisclosure } from "@chakra-ui/react";
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Stack } from "@chakra-ui/react";
import { useCreateTaskMutation, useGetUserQuery, useGetTasksQuery } from "data/slices/users/usersSlice";


// Custom components
import Banner from "views/admin/tasks/components/Tasklist";



import React, { useState, useEffect } from 'react';


import CheckTable from "views/admin/dataTables/components/CheckTable";
import {
  columnsDataCheck,
} from "views/admin/tasks/variables/columnsData.js";
import tableDataCheck from "views/admin/tasks/variables/tableDataCheck.json";
import { date } from "yup";

import { buildTasksTable } from '../../../util/util'
export default function Tasks() {
  const [createTask, { isLoading: isCreatingTask, error: error, data: data }] = useCreateTaskMutation();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()
  const [selectedReminder, setSelectedReminder] = useState("Select");
  const handleReminderSelect = (reminder) => { setSelectedReminder(reminder); };
  const [selectedPriority, setSelectedPriority] = useState('');
  const { data: userData, isLoading: isLoadingUser, isSuccess: isSuccessUser, isError: isErrorUser, error: userError } = useGetUserQuery();
  const { data: tasks, isLoading: isLoadingTasks, isSuccess: isSuccessTasks, isError: isErrorTasks, error: tasksError, refresh } = useGetTasksQuery(userData?.email);

  const handleClose = () => {
    onClose();
    // Set the button's background color here
    // Replace "#A3F579" with your desired color
    document.getElementById("createTaskButton").style.backgroundColor = "#A3F579";
  };
  useEffect(() => {
    // Code to execute when 'tasks' value changes
    console.log('Tasks have changed:', tasks);

  }, [tasks]);
  if (isLoadingUser || isLoadingTasks) {
    return (<Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      height='100vh'
    >
      <Spinner size='xl' />
    </Box>)
  }

  let userTasks = buildTasksTable(tasks);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={"black"}
          fontSize="24"
          fontWeight={"400"}
          lineHeight='100%'>
          {/* <ChevronDownIcon />
          My tasks */}
        </Text>
        <Modal isOpen={isOpen} onClose={handleClose}>
          <ModalOverlay />
          <Formik
            initialValues={{
              ownerEmail: userData.email,
              taskTitle: null,
              priority: null,
              details: null,
              deadline: null,
              reminder: null,
            }}
            onSubmit={(values) => {
              try {
                createTask(values);
                tasks.push(values);
                userTasks = buildTasksTable(tasks);
              } catch (error) {
                console.log(error);
              } finally {
                handleClose();
              }
              //console.log(values);
            }}
          >
            {(formikProps) => (
              <Form>
                <ModalContent borderRadius="20px" width={{ base: "90%", sm: "80%", md: "60%" }}>
                  <ModalBody>
                    <Flex direction="column">
                      <Box mb="20px">
                        <ModalHeader>Name</ModalHeader>
                        <Field name="taskTitle">
                          {({ field, form }) => (
                            <InputGroup size='md'>
                              <Input {...field}
                                isRequired={true}
                                placeholder="Enter task title"
                                borderRadius="20px"
                                size="md" />
                              <FormErrorMessage>
                                {form.errors.taskTitle}
                              </FormErrorMessage>
                            </InputGroup>
                          )}
                        </Field>

                      </Box>
                      <Box mb="20px">
                        <ModalHeader>Priority</ModalHeader>
                        <Stack direction="row" spacing={4} align="center">
                          <Button
                            borderRadius="50px"
                            variant="brand"
                            w="200px"
                            h="10"
                            color="white"
                            background={selectedPriority === 'NONE' ? '#FFA500' : 'gray'}
                            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15)"
                            fontSize="22"
                            fontWeight="400"
                            transition="background 0.3s ease-in-out"
                            _hover={{ background: '#95dd6e' }}
                            isSelected={formikProps.values.priority === 'NONE'}
                            onClick={() => formikProps.setFieldValue('priority', 'NONE')}
                          >
                            No Priority
                          </Button>
                          <Button
                            borderRadius="50px"
                            variant="brand"
                            w="200px"
                            h="10"
                            color="white"
                            background={selectedPriority === 'HIGH' ? '#A3F579' : 'gray'}
                            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15)"
                            fontSize="22"
                            fontWeight="400"
                            transition="background 0.3s ease-in-out"
                            _hover={{ background: '#95dd6e' }}
                            onClick={() => formikProps.setFieldValue('priority', 'HIGH')}
                          >
                            High Priority
                          </Button>
                        </Stack>


                      </Box>
                      <Box mb="20px">
                        <ModalHeader>Details</ModalHeader>
                        <Field name="details">
                          {({ field, form }) => (
                            <InputGroup>
                              <Textarea
                                {...field}
                                isRequired={true}
                                placeholder="Enter task details"
                                borderRadius="20px"
                                size="md"
                                maxW="400px"
                                maxH="60px"
                              />
                              <FormErrorMessage>
                                {form.errors.details}
                              </FormErrorMessage>
                            </InputGroup>
                          )}
                        </Field>
                      </Box>
                      <Flex justifyContent="space-between">
                        <Box>
                          <Flex direction="column" align="flex-start">
                            <ModalHeader>Date and Time</ModalHeader>
                            <Field name="deadline">
                              {({ field, form }) => (
                                <InputGroup>
                                  <Input {...field}
                                    isRequired={true}
                                    placeholder="Select Date and Time" size="md" type="datetime-local" fontSize="sm" width="200px" />

                                  <FormErrorMessage>
                                    {form.errors.deadline}
                                  </FormErrorMessage>
                                </InputGroup>
                              )}
                            </Field>
                          </Flex>
                        </Box>
                        <Box>
                          <Flex direction="column" align="flex-start">
                            <ModalHeader>Reminder</ModalHeader>
                            <Field name="reminder">
                              {({ field }) => (
                                <Menu>
                                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />} ml={3}>
                                    {selectedReminder}
                                  </MenuButton>
                                  <MenuList>
                                    <MenuItem onClick={() => {
                                      handleReminderSelect("On Time")
                                      formikProps.setFieldValue('reminder', 'On Time')
                                    }}>
                                      On Time
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                      handleReminderSelect("1 hr")
                                      formikProps.setFieldValue('reminder', '1 hr before')
                                    }}>
                                      1 hr before
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                      handleReminderSelect("24 hrs")
                                      formikProps.setFieldValue('reminder', '24 hrs before')
                                    }}>
                                      24 hrs before
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                      handleReminderSelect("1 week")
                                      formikProps.setFieldValue('reminder', '24 hrs before')
                                    }}>
                                      1 week before
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              )}
                            </Field>
                          </Flex>
                        </Box>
                      </Flex>
                    </Flex>
                  </ModalBody>
                  <ModalFooter>
                    <Button borderRadius="full" ref={cancelRef} onClick={handleClose} size="lg" mx="auto" display="block" bg="#A3F579">
                      Delete
                    </Button>
                    <Button type="submit" isLoading={isCreatingTask}
                      borderRadius="full" size="lg" mx="auto" display="block" ml={3} bg="#A3F579">
                      Save
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Form>)}
          </Formik>
        </Modal>
        <Button
          id="createTaskButton" // Add this line
          borderRadius="50px"
          variant="brand"
          w="200px"
          h="10"
          color="black"
          background="#A3F579"
          ms="80px"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15)"
          fontSize="22"
          fontWeight="700"
          onClick={onOpen}
          _hover={{ background: "#95dd6e" }}
          _active={{ color: "#95dd6e" }}
          outline="none" // Remove the focus outline
        >
          Create a task
        </Button>


      </Flex>
      <SimpleGrid>
        <CheckTable columnsData={columnsDataCheck} tableData={userTasks} />
      </SimpleGrid>
    </Box>
  );
}

