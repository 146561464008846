import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";

const listingsAdapter = createEntityAdapter()

const initialState = listingsAdapter.getInitialState()

export const listingsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSchoolsData: builder.query({
            query: (keyword) => `/feign/schools?q=${keyword}&page=${1}`,
            providesTags: ['schools']
        }),
        getSubcategoriesMarketplace: builder.query({
            query: () => `/feign/categories/marketplace`,
            providesTags: ['subcategories']
        }),
        getServicesCategories: builder.query({
            query: () => `/feign/categories/services`,
            providesTags: ['servicesCategories']
        }),
        getApparelCategories: builder.query({
            query: () => `/feign/categories/apparel`,
            providesTags: ['apparelCategories']
        }),
        getListing: builder.query({
            query: (listingId) => `/api/listing/${listingId}`,
            invalidatesTags: ['listing'],
            enabled: (listingId) => listingId != null,
            providesTags: ['listing']

        }),
        getListings: builder.query({
            query: (email) => `/api/listings/${email}`,
            providesTags: ['listings'],
            enabled: (email) => (email != null && email != ''),
            providesTags: ['listings']
        }),
        getListingsStatus: builder.query({
            query: (id) => `/api/submission/status/${id}`,
            providesTags: ['listingStatus'],
            enabled: (id) => (id != null && id != '')
        }),
        addMarketplaceListing: builder.mutation({
            query: (formData) => ({
                url: '/api/listing/marketplace',
                method: 'POST',
                body: formData
            }),
            invalidatesTags: ['listings']

        }),
        addHousingListing: builder.mutation({
            query: (formData) => ({
                url: '/api/listing/house',
                method: 'POST',
                body: formData
            }),
            invalidatesTags: ['listings']

        }),
        addApparelListing: builder.mutation({
            query: (formData) => ({
                url: '/api/listing/apparel',
                method: 'POST',
                body: formData
            }),
            invalidatesTags: ['listings']

        }),
        addServiceListing: builder.mutation({
            query: (formData) => ({
                url: '/api/listing/service',
                method: 'POST',
                body: formData
            }),
            invalidatesTags: ['listings']

        }),
        editMarketplaceListing: builder.mutation({
            query: (formData) => ({
                url: '/api/listing/marketplace',
                method: 'PUT',
                body: formData
            }),
            invalidatesTags: ['listings', 'listing']
        }),

        editHousingListing: builder.mutation({
            query: (formData) => ({
                url: '/api/listing/house',
                method: 'PUT',
                body: formData
            }),
            invalidatesTags: ['listings', 'listing']
        }),

        editApparelListing: builder.mutation({
            query: (formData) => ({
                url: '/api/listing/apparel',
                method: 'PUT',
                body: formData
            }),
            invalidatesTags: ['listings', 'listing']
        }),

        editServiceListing: builder.mutation({
            query: (formData) => ({
                url: '/api/listing/service',
                method: 'PUT',
                body: formData
            }),
            invalidatesTags: ['listings', 'listing']
        }),
        deleteListing: builder.mutation({
            query: (listingId) => ({
                url: `/api/listing/${listingId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['listings'],
        }),
        changeStatus: builder.mutation({
            query: (data) => ({
                url: '/api/listing/status',
                method: 'PUT',
                params: {
                    listingId: data?.id,
                    status: data?.newStatus
                }
            }),
            invalidatesTags: ['listings', 'listing', 'listingStatus', 'userStats']
        }),
    }),
})

export const {
    useGetSubcategoriesMarketplaceQuery,
    useGetSchoolsDataQuery,
    useAddApparelListingMutation,
    useAddHousingListingMutation,
    useAddMarketplaceListingMutation,
    useAddServiceListingMutation,
    useEditMarketplaceListingMutation,
    useEditHousingListingMutation,
    useEditApparelListingMutation,
    useEditServiceListingMutation,
    useDeleteListingMutation,
    useChangeStatusMutation,
    useGetListingsStatusQuery,
    useGetListingsQuery,
    useGetListingQuery,
    useGetServicesCategoriesQuery,
    useGetApparelCategoriesQuery,
} = listingsApiSlice



