/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Spinner, Text, Box, Grid, Flex, Button, Icon, SimpleGrid, useColorModeValue
} from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/profile/components/Banner";
import General from "views/admin/profile/components/General";
import Notifications from "views/admin/profile/components/Notifications";
import Projects from "views/admin/profile/components/Projects";
import SubscriptionCard from "views/admin/profile/components/SubscriptionCard";
import PaymentCard from "views/admin/profile/components/PaymentCard";
import PasswordCard from "views/admin/profile/components/PasswordCard";
import VerificationCard from "views/admin/profile/components/VerificationCard";
import { MdOutlineLogout } from "react-icons/md";
import Cookies from 'js-cookie';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { useLogoutMutation } from "../../../data/slices/auth/authApiSlice";
import { apiSlice } from "../../../data/api/apiSlice";
import { subSlice } from "../../../data/api/subSlice";
// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import React, { useEffect } from "react";
import { useGetUserQuery } from "../../../data/slices/users/usersSlice";
import { useGetSubDetailsQuery } from "../../../data/slices/subscription/subscription";
import { setAuthenticated } from "../../../data/slices/auth/authSlice";
export default function Overview() {

  const { data: userDetails, isLoading: isLoadingUser, isSuccess: isSuccessUser, isError: isErrorUser, error: userError } = useGetUserQuery();
  const { data: subDetails, isLoading: isLoadingSub, isSuccess: isSuccessSub, isError: isErrorSub, error: subError } = useGetSubDetailsQuery(userDetails?.email ?? "");
  const history = useHistory();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const dispatch = useDispatch()
  const [logout, { isLoading: isLoggingOut }] = useLogoutMutation()
  // useEffect(() => {
  //   console.log(userDetails)
  // }, [userDetails]);

  const handleLogout = async () => {
    await logout();
    await dispatch(setAuthenticated(false));
    dispatch(apiSlice.util.resetApiState());
    dispatch(subSlice.util.resetApiState());
    localStorage.clear();
    history.push('/auth');
  };

  if (isLoadingUser || isLoadingSub) {
    return (
      <Box display='flex' alignItems='center' justifyContent='center' height='100vh'>
        <Spinner size='xl' />
      </Box>
    )
  }

  if (isErrorUser || isErrorSub) {
    const error = isErrorUser ? userError : subError;
    return (
      <Box display='flex' alignItems='center' justifyContent='center' height='100vh'>
        <Text color={textColor} fontSize='34px' fontWeight='700' lineHeight='100%'>
          Error: {error.status}
        </Text>
      </Box>
    )
  }

  const {
    id,
    firstName,
    lastName,
    orgName,
    email,
    schoolAffiliation,
    phoneNumber,
    orgType,
    isEmailVerified,
    isPhoneVerified,
    isEmailNotificationEnabled,
    numTotalListingsSold,
    totalPostsHistory,
    notificationHistory,
    lastLoggedIn,
    createdOn,
    updatedOn
  } = userDetails;
  const name = firstName + " " + lastName;
  return (
    <Box pt={{ xl: "80px" }} bg='#ecf9e5' align='center'>
      {/* Main Fields */}
      <SimpleGrid gap='10px'>
        <SimpleGrid gap='10px'
          templateColumns={{
            base: "1fr",
            lg: "1fr 1fr 1fr",
          }}
          templateRows={{
            base: "repeat(3, 1fr)",
            lg: "1fr",
          }}
        >
          <Flex flexDirection={'column'} mt='10px' mb='10px' justify='space-between'>
            <Banner align='center'
              banner={banner}
              avatar={avatar}
              name={name}
              job={orgType}
              posts='17'
              followers='k'
              following='274'
            />
            <Button
              // mt="10px"
              w='95%'
              h='40%'
              fontWeight='semibold'
              color='BLack'
              type="submit"
              borderRadius='30px'
              fontSize='25px'
              leftIcon={<Icon as={MdOutlineLogout} width='20px' height='20px' color='inherit' />}
              onClick={handleLogout}

            >

              Log out
            </Button>
          </Flex>

          <PaymentCard phone={phoneNumber} email={email}
            isEmailVerified={isEmailVerified} isPhoneVerified={isPhoneVerified} />
          <SubscriptionCard subDetails={subDetails} userEmail={email} />
        </SimpleGrid>
        <Grid
          templateColumns={{
            base: "1fr",
            lg: "1.34fr 1fr 1.62fr",
          }}
          templateRows={{
            base: "repeat(3, 1fr)",
            lg: "1fr",
          }}
          gap={{ xl: "20px" }}>
          <PasswordCard
            gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
            used={25.6}
            total={50}
            email={email}
          />
        </Grid>
      </SimpleGrid>
    </Box>
  );
}
