/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Spinner, Box, SimpleGrid, Select, Text, useColorModeValue } from "@chakra-ui/react";
import DevelopmentTable from "views/admin/leads/components/DevelopmentTable";
import CheckTable from "views/admin/leads/components/CheckTable";
import ColumnsTable from "views/admin/leads/components/ColumnsTable";
import ComplexTable from "views/admin/leads/components/ComplexTable";
import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from "views/admin/leads/variables/columnsData";
import React, { useEffect } from "react";
import { useGetUserQuery, useGetLeadsQuery } from "../../../data/slices/users/usersSlice";

export default function Settings() {
  // Chakra Color Mode
  const { data: userDetails, isLoading: isLoadingUser, isSuccess: isSuccessUser, isError: isErrorUser, error: userError } = useGetUserQuery();

  const {
    data: leads,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetLeadsQuery(userDetails?.email ?? '');
  const textColor = useColorModeValue("secondaryGray.900", "white");

  useEffect(() => {
    console.log(leads);

  }, [leads]); 
  if (isLoading || isLoadingUser) {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        height='100vh'
      >
        <Spinner size='xl' />
      </Box>
    )
  }

  if (isError || isErrorUser) {
    return <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      height='100vh'
    >      <Text
      color={textColor}
      fontSize='34px'
      fontWeight='700'
      lineHeight='100%'>Error: {error.status}</Text>
    </Box>
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ md: 1 }}
        spacing={{ base: "20px", xl: "1px" }}>
        {/* <DevelopmentTable
          columnsData={columnsDataDevelopment}
          tableData={tableDataDevelopment}
        />
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <ColumnsTable
          columnsData={columnsDataColumns}
          tableData={tableDataColumns}
        /> */}
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={leads}
        />
      </SimpleGrid>
    </Box>
  );
}
