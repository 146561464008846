import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { subSlice } from "../../api/subSlice";

const subscriptionAdapter = createEntityAdapter()

const initialState = subscriptionAdapter.getInitialState()

export const subApiSlice = subSlice.injectEndpoints({
    endpoints: builder => ({
        getSubManagementPortalById: builder.mutation({
            query(email) {
                return {
                    url: `/api/create-customer-portal-session/${email}`,
                    method: 'GET',
                    // mode: 'cors',
                    // transformResponse: (response, result) => {
                    //     console.log("reS");
                    //     console.log(response);
                    //     if (response.redirected) {
                    //         window.location.href = response.url;
                    //     }
                    //     return (response.status === 302);
                    // },
                    //  redirect: "follow"
                }
            },
            providesTags: ['sub'],
            enabled: (email) => email != null

        }),
        getSubDetails: builder.query({
            query: (email) => `/api/subscription/details/${email}`,
            providesTags: ['sub'],
            enabled: (email) => email != null

        }),
    }),
})

export const {
    useGetSubDetailsQuery,
    useGetSubManagementPortalByIdMutation
} = subApiSlice



