// Chakra imports
import {
    Box,
    Flex,
    Icon,
    Progress,
    Text,
    useColorModeValue,
    Switch,
    FormLabel,
    FormControl,
    SimpleGrid,
    Button,
    InputRightElement,
    FormHelperText,
    FormErrorMessage,
    Alert,
    AlertIcon
} from "@chakra-ui/react";
// Custom components
import { Formik, Form, Field } from "formik";
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import Menu from "components/menu/MainMenu";
import React from "react";
import { useRef, useState, useEffect } from 'react'
import {
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
} from "@chakra-ui/react";
// Assets
import { useChangePasswordMutation } from '../../../../data/slices/auth/authApiSlice';

export default function PasswordCard(props) {
    const inputBg = useColorModeValue("secondaryGray.300");
    const inputText = useColorModeValue("gray.700", "gray.100");
    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)
    const [changePassword, { isLoading, isSuccess, isError }] = useChangePasswordMutation();

    const [errMsg, setErrMsg] = useState('')
    return (
        <Card mb={{ base: "0px", lg: "18px" }} width='95%' align='center' height='500px' borderRadius='30px'>
            <Text color='black' fontSize='30' mt='10px' variant='brand'>
                Change Password
            </Text>
            <Flex h='10px'></Flex>
            {errMsg && (
                <Alert status='error'>
                    <AlertIcon />
                    {errMsg}
                </Alert>
            )}
            {isSuccess && (
                <Alert status='success'>
                    <AlertIcon />
                    {"Password Changed Successfully!"}
                </Alert>)}
            <Formik
                initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    confirmNewPassword: "",
                }}
                onSubmit={async (values, actions) => {
                    if (values.newPassword.length < 6) {
                        setErrMsg("Password must be at least 6 characters long");
                    } else if (values.newPassword !== values.confirmNewPassword) {
                        setErrMsg("Passwords do not match");
                    } else if (values.newPassword === values.oldPassword) {
                        setErrMsg("Old and new password can not be the same");
                    } else {
                        try {
                            var data = {
                                oldPassword: values.oldPassword,
                                newPassword: values.newPassword,
                                email: props.email
                            }
                            await changePassword(data).unwrap();
                            setErrMsg('')
                            actions.resetForm({});
                        } catch (err) {
                            switch (err?.status) {
                                case undefined:
                                    setErrMsg('No server response, please try again later!');
                                    break;
                                case 401:
                                    setErrMsg('The current password is incorrect!');
                                    break;
                                default:
                                    setErrMsg('Unknown server error, contact support@collegesouk.com for assistance!');
                            }
                        }
                        actions.setSubmitting(false);
                    }
                }}
            >
                {(props) => (
                    <Form >
                        <FormControl variant="floating" >

                            <Box w='100%' mt='10px'>
                                <Flex w='100%' justify='space-between' >

                                    <Field name="oldPassword">
                                        {({ field }) => (
                                            <InputGroup size="md">
                                                <Input
                                                    {...field}
                                                    id="oldPassword"
                                                    variant="search"
                                                    fontSize="sm"
                                                    bg={inputBg}
                                                    type={show ? "text" : "password"}
                                                    color={inputText}
                                                    fontWeight="500"
                                                    _placeholder={{ color: "gray.400", fontSize: "14px" }}
                                                    borderRadius={"30px"}
                                                />
                                                <InputRightElement width="4.5rem">
                                                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                                                        {show ? "Hide" : "Show"}
                                                    </Button>
                                                </InputRightElement>
                                            </InputGroup>
                                        )}
                                    </Field>
                                </Flex>

                                <Box w='100%' >
                                    <Flex w='100%' justify='space-between' mb='10px'>
                                        <Text color={'black'} fontSize='l' mt='10px' align='left' ms='7px'>Current password</Text>
                                    </Flex>
                                </Box>
                                <Flex h='20px'></Flex>
                                <Flex w='100%' justify='space-between' >

                                    <Field name="newPassword">
                                        {({ field, meta }) => (
                                            <InputGroup size="md">
                                                <Input
                                                    {...field}
                                                    id="newPassword"
                                                    variant="search"
                                                    fontSize="sm"
                                                    bg={inputBg}
                                                    type={show ? "text" : "password"}
                                                    color={inputText}
                                                    fontWeight="500"
                                                    _placeholder={{ color: "gray.400", fontSize: "14px" }}
                                                    borderRadius={"30px"}
                                                />
                                            </InputGroup>


                                        )}
                                    </Field>
                                </Flex>
                                <Box w='100%' >
                                    <Flex w='100%' justify='space-between' mb='10px'>
                                        <Text color={'black'} fontSize='l' mt='10px' align='left' ms='7px'>New Password</Text>
                                    </Flex>
                                </Box>
                                <Flex h='20px'></Flex>

                                <Flex w='100%' justify='space-between' >

                                    <Field name="confirmNewPassword">
                                        {({ field }) => (
                                            <InputGroup size="md">
                                                <Input
                                                    {...field}
                                                    variant="search"
                                                    fontSize="sm"
                                                    bg={inputBg}
                                                    type={show ? "text" : "password"}
                                                    color={inputText}
                                                    fontWeight="500"
                                                    _placeholder={{ color: "gray.400", fontSize: "14px" }}
                                                    borderRadius={"30px"}
                                                />

                                            </InputGroup>
                                        )}
                                    </Field>
                                </Flex>

                                <Box w='100%' >
                                    <Flex w='100%' justify='space-between' mb='10px'>
                                        <Text color={'black'} fontSize='l' mt='10px' align='left' ms='7px'>Confirm New Password</Text>
                                    </Flex>
                                </Box>
                                <Flex h='20px'></Flex>

                                <Box w='100%' mt='1px'>
                                    <Flex w='100%' align='center' justify='space-between'>
                                        <Button
                                            bg="#A3F579"
                                            w='280px'
                                            minW='200px'
                                            mt={{ base: "25px", "2l": "auto" }}
                                            color={"black"}
                                            fontWeight='1000'
                                            mx='auto'
                                            type="submit"
                                            _hover={{ bgColor: "#95dd6e" }}
                                            isLoading={props.isSubmitting}

                                        >
                                            Save changes
                                        </Button>
                                    </Flex>
                                </Box>



                            </Box>
                        </FormControl>
                    </Form>

                )}
            </Formik>
        </Card >
    );
}
