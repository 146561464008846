import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import RTLLayout from "layouts/rtl";
import LoginPage from "./views/auth/signIn";

import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
// redux data management stuff
import Cookies from 'js-cookie';
import { useSelector } from "react-redux";
import { selectAuthenticated } from './data/slices/auth/authSlice';
import { useEffect } from "react";


const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (
            isAuthenticated ? (
                <Component {...props} />
            ) : (
                <Redirect to={{
                    pathname: '/auth',
                    state: { from: props.location }
                }} />
            )
        )}
    />
);

function App() {
    //var isAuthenticated = Cookies.get('SOUK_TOKEN') != null;
    const isAuthenticated = useSelector(selectAuthenticated);

    useEffect(() => {
        console.log("Is authenticated:", isAuthenticated);
      }, [isAuthenticated]);
    return (
        <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <PrivateRoute path={`/admin`} component={AdminLayout} isAuthenticated={isAuthenticated} />
            {/* <Route path={`/rtl`} component={RTLLayout} /> */}
            <Redirect from='/' to='/admin' />
        </Switch>
    )
}

export default App;