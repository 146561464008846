import { useState } from 'react'
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Text,
} from '@chakra-ui/react'
import DefaultAuth from "layouts/auth/ResetAuth";
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useSendResetPasswordMutation } from '../../../data/slices/auth/authApiSlice'

function ForgotPassword() {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)
    const [sendResetLink, { isLoading }] = useSendResetPasswordMutation();

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const s = sendResetLink(email).unwrap();
            setSuccess(true)
        } catch (err) {
            setError(err.message)
        }
    }

    return (
        <DefaultAuth align='center' >
            <Box p={[10]} mt={[1000, '2vh']} direction='column'
                w='500px'
                h='700px'
                maxW='100%'
                borderRadius='15px'
            >
                <Heading fontWeight='normal' color={"black"} mb={35} fontSize={25} textAlign={"center"}>Reset your password</Heading>
                {!success ? (
                    <Box as='form' onSubmit={handleSubmit}>
                        <FormControl id='email' isRequired isInvalid={error}>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='md'
                                fontFamily={'arial'}
                                fontWeight='light'
                                color='black'
                                mb='2px'>
                                Email<Text color='black'></Text>
                            </FormLabel>
                            <Input
                                id="email"
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    setEmail(e.target.value)
                                }}
                                isRequired={true}
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='email'
                                placeholder='mail@collegesouk.com'
                                mb='24px'
                                fontWeight='500'
                                size='lg'
                                bg='white'
                            />
                            <FormErrorMessage>{error}</FormErrorMessage>
                        </FormControl>
                        <Button
                            fontSize='lg'
                            fontWeight='semibold'
                            variant='brand'
                            color='black'
                            w='250px'
                            h='50'
                            mb='24px'
                            mt='20px'
                            isLoading={false}
                            type="submit"
                            background='#A3F579'
                            ms='80px'
                            boxShadow='0px 4px 4px rgba(0, 0, 0, 0.15)'
                            borderRadius='50px'
                            onSubmit={handleSubmit}
                            // on actions
                            _hover={{ background: '#95dd6e' }}>
                            Send me instructions
                        </Button>
                    </Box>
                ) : (

                    <Box display="flex" alignItems="center">
                        <Text alignItems="center">
                            An email has been sent to <b>{email}</b> with instructions to reset your
                            password
                            <CheckCircleIcon color="green.500" ml={2} mr={2} />

                        </Text>

                    </Box>

                )}
            </Box>

        </DefaultAuth >
    )
}

export default ForgotPassword
