// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";

import {
  MdOutlineLogout
} from "react-icons/md";
export default function Banner(props) {
  const { banner, avatar, name, job, posts, followers, following } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  return (
    <Card mb={{ base: "0px", lg: "18px" }} width='95%' align='center' height='100%' borderRadius='30px'>
      <Flex w='90%' justify='space-between' mb='10px' h='0px'>
        <Flex align='center'>
        </Flex>
        <Text color={'gray'} fontSize='l' mt='5px' align='left' >
          Edit
        </Text>
      </Flex>
      <Avatar
        mx='auto'
        name={name}
        fontSize='240px' // increased font size to 240px
        bg='#11047A'
        h='160px'
        w='160px'
        mt='27px'
        border='4px solid'
        borderColor={borderColor}
      />
      <Text color='black' fontWeight='bold' fontSize='30' mt='20px' >
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize='20' mt='-6px'>
        {job}
      </Text>
    </Card>
  );
}
