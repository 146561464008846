import React, { useState, useRef, useEffect } from "react";
import {
    Spinner,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Icon,
    Box,
    List,
    ListItem,
    ListIcon,
    Text,
    Divider
} from "@chakra-ui/react";
import {
    MdOutlinePlace
} from "react-icons/md";
import { Search2Icon } from "@chakra-ui/icons";
import axios from 'axios';

const GooglePlacesInput = ({ apiKey, placeholder, setFieldValue, value, isDisabled, ...rest }) => {
    const [query, setQuery] = useState(value ? value : "");
    const [results, setResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // add state for loading

    const inputRef = useRef(null);

    useEffect(() => {
        const handleClick = (e) => {
            if (inputRef.current && !inputRef.current.contains(e.target)) {
                setShowResults(false);
            }
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);

    const handleInputChange = async (e) => {
        inputRef.current.focus();
        const value = e.target.value;
        setQuery(value);
        
        if (value != null && value.length > 2) {
            setIsLoading(true); // set loading state to true
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_DASH_API_BASEURL}/api/location/autocomplete?q=${query}`
                );
                const data = response.data;
                setResults(data);
                setShowResults(true);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false); // set loading state to false
            }
        } else {
            setResults([]);
            setShowResults(false);
        }
    };
    

    const handleResultClick = async(result) => {
        setQuery(result.displayAddress);
        await setFieldValue("address", result.displayAddress);
        await setFieldValue("placeId", result.placeId);
        setResults([]);
        setShowResults(false);
        inputRef.current.focus();
    };


    return (
        <Box ref={inputRef}>
            <InputGroup>
                <InputLeftElement
                    pointerEvents="none"
                    children={<Icon as={Search2Icon} color="gray.300" />}
                />
                <Input
                    type="text"
                    placeholder={placeholder}
                    value={query}
                    onChange={handleInputChange}
                    isDisabled={isDisabled}
                    {...rest}
                />
            </InputGroup>
            {results != null && showResults && results.length > 0 && (
                <List mt={2} maxH="200px" overflowY="auto" w="100%">
                    {results.map((result) => (
                        <ListItem
                            key={result.placeId}
                            fontSize="xs"
                            cursor="pointer"
                            _hover={{ bg: "gray.100" }}
                            onClick={() => handleResultClick(result)}
                        >
                            <Flex alignItems="center">
                                <Icon as={MdOutlinePlace} color="gray.300" mr="2" />
                                <Box flex="1">
                                    <Text fontWeight="bold">
                                        {result.displayAddress}
                                    </Text>
                                    <Text>
                                        {result.displayAddress}
                                    </Text>
                                </Box>
                            </Flex>
                        </ListItem>
                    ))}
                </List>
            )}
            {isLoading && (
                <Spinner
                    color="#95dd6e"
                    position="absolute"
                    left="50%"
                    top="50%"
                    transform="translate(-50%, -50%)"
                />
            )}
        </Box>
    );
};

export default GooglePlacesInput;

