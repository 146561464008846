import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  SimpleGrid,

} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { getTimeAgo } from "../../../../util/util";
import { Button, ButtonGroup, IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
// Assets
import { MdCheckCircle, MdCancel, MdOutlineError, MdVisibility, MdOutlineTimer, MdDone, MdAttachMoney } from "react-icons/md";
export default function ColumnsTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);


  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }, // Set initial page index and page size
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize = 15 },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
  } = tableInstance;

  //initialState.pageSize = 15;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Card
      direction='column'
      px='0px'
      borderRadius='30px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Current Listings
        </Text>
        <Menu />
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "TITLE") {
                    data = (
                      <Text nOfLines={1} color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "CATEGORY") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "STATUS") {
                    data = (
                      <Flex align='center'>
                        <Icon
                          w='24px'
                          h='24px'
                          me='5px'
                          color={
                            cell.value === "Approved"
                              ? "green.500"
                              : cell.value === "Rejected"
                                ? "red.500"
                                : cell.value === "Changes Required"
                                  ? "orange.500"
                                  : cell.value === "Sold"
                                    ? "#66BB6A"
                                    : null
                          }
                          as={
                            cell.value === "Approved"
                              ? MdCheckCircle
                              : cell.value === "Rejected"
                                ? MdCancel
                                : cell.value === "Changes Required"
                                  ? MdOutlineError
                                  : cell.value === "Pending"
                                    ? MdOutlineTimer
                                    : cell.value === "Sold"
                                      ? MdAttachMoney
                                      : null
                          }
                        />
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "SUBMISSION DATE") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "ELAPSED TIME") {
                    data = (
                      <Flex align='center'>
                        {/* <Progress
                          variant='table'
                          colorScheme='camelColor'
                          h='8px'
                          w='108px'
                          value={cell.value}
                        /> */}
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {getTimeAgo(cell.value)}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "COMMENTS") {
                    data = (
                      <Flex align='center'>
                        <Flex align='center'>
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.value}
                          </Text>
                        </Flex>
                      </Flex>
                    );
                  };
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex justifyContent='center' mt='2'>
        <ButtonGroup size='sm' isAttached variant='outline'>
          <IconButton
            icon={<Icon as={ChevronLeftIcon} />}
            onClick={previousPage}
            disabled={!canPreviousPage}
            aria-label='Previous Page'
          />
          {Array.from({ length: pageCount }, (_, index) => (
            <Button
              key={index}
              variant='outline'
              colorScheme={pageIndex === index ? '#95dd6e' : undefined}
              onClick={() => gotoPage(index)}
            >
              {index + 1}
            </Button>
          ))}
          <IconButton
            icon={<Icon as={ChevronRightIcon} />}
            onClick={nextPage}
            disabled={!canNextPage}
            aria-label='Next Page'
          />
        </ButtonGroup>
      </Flex>
    </Card>
  );
}
