import React from "react";
import { NavLink, useHistory } from "react-router-dom";
// Chakra imports
import {
  Stack,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  FormErrorMessage,
  Alert,
  AlertIcon
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';

// https://www.npmjs.com/package/@react-oauth/google - Google OAuth

// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useRegisterMutation, useGoogleRegisterMutation } from '../../../data/slices/auth/authApiSlice'
import { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setAuthenticated } from "../../../data/slices/auth/authSlice";
import { useGoogleLogin } from '@react-oauth/google';


function SignUp(props) {
  // Chakra color mode
  const history = useHistory();
  const [errMsg, setErrMsg] = useState('')
  // const navigate = useNavigate()
  const dispatch = useDispatch()

  const [register, { isLoading }] = useRegisterMutation()
  const [registerWithGoogle, { isLoading: isLoadingGoogleRegistration }] = useGoogleRegisterMutation();

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const googleRegister = useGoogleLogin({
    onSuccess: tokenResponse => handleAccessTokenResponse(tokenResponse?.access_token),
  });

  const handleAccessTokenResponse = async (token) => {
    try {
      await registerWithGoogle(token).unwrap()
      await dispatch(setAuthenticated(true));
      history.push("/admin/default");
      // if (!values.rememberMe) {
      //   Cookies.remove("SOUK_TOKEN");
      // }
    } catch (err) {
      switch (err?.status) {
        case undefined:
          setErrMsg('No server response, please try again later!');
          break;
        case 409:
          setErrMsg('This email address is associated with an existing account. Please try logging in or contact us.');
          break;
        case 401:
          setErrMsg('Unauthorized!');
          break;
        default:
          setErrMsg('Unknown error, contact support@collegesouk.com');
      }
      //errRef.current.focus();
    }
  }
  const handleSubmit = async (values) => {
    try {
      await register({
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName
      }).unwrap()
      await dispatch(setAuthenticated(true));
      history.push("/admin/default");
      // if (!values.rememberMe) {
      //   Cookies.remove("SOUK_TOKEN");
      // }
    } catch (err) {
      switch (err?.status) {
        case undefined:
          setErrMsg('No server response, please try again later!');
          break;
        case 409:
          setErrMsg('This email address is associated with an existing account. Please try logging in or reset your password if needed.');
          break;
        case 401:
          setErrMsg('Unauthorized!');
          break;
        default:
          setErrMsg('Unknown error, contact support@collegesouk.com');
      }
      //errRef.current.focus();
    }
  }

  return (
    <DefaultAuth 
      illustrationBackground={illustration} 
      align='center'
    >
      <Box bg='#eef8e7' p={[10]} mt={[1000, '2vh']} direction='column'
        w='500px'
        h='760px'
        maxW='100%'
        borderRadius='15px'


      >
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w='100%'
          mx={{ base: "auto", lg: "0px" }}
          me='auto'
          h='100%'
          alignItems='start'
          justifyContent='center'
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "2vh" }}
          flexDirection='column'>

          <Box me='auto' >
            <Heading color={textColor} fontSize='36px' mb='10px' fontFamily={'arial'} fontWeight='light'>
              Sign up
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColor}
              fontWeight='400'
              fontSize='md'>
              Enter your details below to sign up!
            </Text>
          </Box>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}>
            <Button
              fontSize='md'
              me='0px'
              mb='26px'
              py='15px'
              h='50px'
              borderRadius='16px'
              bg='white'
              color={googleText}
              fontWeight='500'
              _hover={googleHover}
              _active={googleActive}
              onClick={googleRegister}
              _focus={googleActive}>
              <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
              Continue with Google
            </Button>
            <Flex align='center' mb='25px'>
              <Divider borderColor={'black'} />
              <Text color='black' mx='14px'>
                or
              </Text>
              <Divider borderColor={'black'} />
            </Flex>
            {errMsg ?
              <Flex align='center' mb='25px'>
                <Alert status='error'>
                  <AlertIcon />
                  {errMsg}
                </Alert>
                {/* <Text bold color='red' mx='14px'>
                  {errMsg}
                </Text> */}
              </Flex> : null}
            <Formik
              initialValues={{ firstName: null, lastName: null, email: null, companyName: null, companyFunction: null, password: null, passwordConfirm: null, agreeTermsOfService: false }}
              onSubmit={async (values, actions) => {
                if (values.password != values.passwordConfirm) {
                  setErrMsg("Password confirmation doesn't match Password");
                  actions.setSubmitting(false);
                } else if (values.password && values.password.length < 6) {
                  setErrMsg("Password is too short (minimum is 6 characters)");
                  actions.setSubmitting(false);
                } else {
                  await handleSubmit(values);
                }
              }}
            >
              {(props) => (
                <Form >
                  <Stack direction='row'>

                    <Field name="firstName" >
                      {/* Trouble in this line below*/}
                      {({ field, form }) => (
                        <FormControl
                        >
                          <FormLabel
                            display='flex'
                            ms='4px'
                            fontSize='md'
                            fontWeight='500'
                            color={textColor}
                            mb='2px'>
                            First Name<Text color={brandStars}></Text>
                          </FormLabel>
                          <Input
                            {...field} id="firstName"
                            placeholder="First Name"
                            isRequired={true}
                            variant='auth'
                            fontSize='md'
                            ms={{ base: "0px", md: "0px" }}
                            type='text'
                            mb='24px'
                            fontWeight='500'
                            size='lg'
                            background='white'
                          />
                      
                          <FormErrorMessage>
                            {form.errors.firstName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="lastName" >
                      {/* Trouble in this line below*/}
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel
                            display='flex'
                            ms='4px'
                            fontSize='md'
                            fontWeight='500'
                            color={textColor}
                            mb='2px'>
                            Last Name<Text color={brandStars}></Text>
                          </FormLabel>
                          <Input
                            {...field} id="lastName"
                            placeholder="Last Name"
                            isRequired={true}
                            variant='auth'
                            fontSize='md'
                            ms={{ base: "0px", md: "0px" }}
                            type='text'
                            mb='24px'
                            fontWeight='500'
                            size='lg'
                            background='white'
                          />
                          <FormErrorMessage>
                            {form.errors.lastName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <Field name="email" >
                    {/* Trouble in this line below*/}
                    {({ field, form }) => (
                      <FormControl
                      >
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='md'
                          fontWeight='500'
                          color={textColor}
                          mb='2px'
                        >
                          Email<Text color={brandStars}></Text>
                        </FormLabel>

                        <Input
                          {...field} id="email"
                          placeholder="Email"
                          isRequired={true}
                          variant='auth'
                          fontSize='md'
                          ms={{ base: "0px", md: "0px" }}
                          type='email'
                          mb='24px'
                          fontWeight='500'
                          size='lg'
                          background='white'
                        />
                        <FormErrorMessage>
                          {form.errors.email}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="password" >
                    {/* Trouble in this line below*/}
                    {({ field, form }) => (
                      <FormControl
                      >
                        <FormLabel
                          ms='4px'
                          fontSize='md'
                          fontWeight='500'
                          color={textColor}
                          isRequired={true}
                          display='flex'>
                          Password<Text color={brandStars}></Text>
                        </FormLabel>
                        <InputGroup size='md'>
                          <Input
                            {...field} id="password"
                            isRequired={true}
                            placeholder="Password"
                            fontSize='md'
                            mb='24px'
                            size='lg'
                            type={show ? "text" : "password"}
                            variant='auth'
                            background='white'
                          />
                          <InputRightElement display='flex' alignItems='center' mt='4px'>
                            <Icon
                              color={textColorSecondary}
                              _hover={{ cursor: "pointer" }}
                              as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                              onClick={handleClick}
                            />
                          </InputRightElement>


                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.password}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="passwordConfirm" >
                    {/* Trouble in this line below*/}
                    {({ field, form }) => (
                      <FormControl
                      >
                        <FormLabel
                          ms='4px'
                          fontSize='md'
                          fontWeight='500'
                          color={textColor}
                          isRequired={true}
                          display='flex'>
                          Confirm Password<Text color={brandStars}></Text>
                        </FormLabel>
                        <InputGroup size='md'>
                          <Input
                            {...field} id="passwordConfirm"
                            placeholder="Password"
                            isRequired={true}
                            fontSize='md'
                            mb='24px'
                            size='lg'
                            type={show ? "text" : "password"}
                            variant='auth'
                            background='white'
                          />
                          <InputRightElement display='flex' alignItems='center' mt='4px'>
                            <Icon
                              color={textColorSecondary}
                              _hover={{ cursor: "pointer" }}
                              as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                              onClick={handleClick}
                            />
                          </InputRightElement>


                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.passwordConfirm}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Flex
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='start'
                    maxW='100%'
                    mb='-10px'
                  >
                  </Flex>
                  <Button

                    fontSize='lg'
                    fontWeight='semibold'
                    variant='brand'
                    color='black'
                    w='250px'
                    h='50'
                    mb='3px'
                    isLoading={props.isSubmitting || isLoadingGoogleRegistration}
                    type="submit"
                    background='#A3F579'
                    ms='80px'
                    boxShadow='0px 4px 4px rgba(0, 0, 0, 0.15)'
                    borderRadius='50px'
                    _hover={{ background: '#95dd6e' }}>
                    Go!
                  </Button>
                </Form>
              )}
            </Formik>
          </Flex>
        </Flex>
      </Box>
    </DefaultAuth>
  );
}

export default SignUp;