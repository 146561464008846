// Chakra imports
// Chakra imports
import {
Divider,
Flex,
Stat,
StatLabel,
StatNumber,
useColorModeValue,
defineStyle,
defineStyleConfig,
Text,
extendTheme,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";

// Custom icons
import React from "react";

export default function Default(props) {
const { startContent, endContent, name, growth, value, borderColor } = props;
const textColor = useColorModeValue("secondaryGray.900", "white");
const textColorSecondary = "secondaryGray.600";

return (
    <Card py='15px' borderRadius='30px'>
        <Flex
            my='auto'
            me='-20px' 
            >
            {startContent}
            <Stat my='auto' ms={startContent ? "18px" : "0px"}>
            <Divider borderColor = {borderColor} mb = '10px'/>
            <StatNumber
                mb = '10px'
                color={textColor}
                fontSize={{
                base: "2xl",
                }}
                >
                {value}
            </StatNumber>
            <StatLabel
                lineHeight='100%'
                align='start'
                color={textColorSecondary}
                fontSize={{
                base: "sm",
                }}>
                {name}
            </StatLabel>
            </Stat>
        </Flex>
        
    </Card>
    
);
}
