
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import Upload from "views/admin/listing/components/Upload";

// Assets
import React from "react";

export default function Overview() {
  return (
    <Box pt={{ base: "130px", md: "8px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        gap={{ base: "10px", xl: "2px" }}>
        <Upload
          gridArea='1 / 1 / 2 / 2'
          minH={{ base: "auto", lg: "310px", "2xl": "365px" }}
          pe='25px'
          pb={{ base: "10px", lg: "20px" }}
        />
      </Grid>

    </Box>
  );
}
