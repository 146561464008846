// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  SimpleGrid,
  useColorModeValue,

} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import React from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdBarChart, MdOutlineCalendarToday } from "react-icons/md";
// Assets
import { RiArrowUpSFill } from "react-icons/ri";
import {
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "variables/charts";
import MiniCardStatistics from "components/card/MiniCardStatistics";
import { populateSalesChartData, calculateTotalSales, getAbbreviatedMonthNames, calculateTotalImpressions, populateImpressionsChartData, formatBigNumbers } from "../../../../util/util";


export default function TotalSpent(props) {
  const { userDetails, userStats, ...rest } = props;

  const { lastSixMonthSales } = userStats;
  const totalSales = calculateTotalSales(lastSixMonthSales);
  const totalImpressions = calculateTotalImpressions(lastSixMonthSales);
  lineChartOptionsTotalSpent.xaxis.categories = getAbbreviatedMonthNames(lastSixMonthSales);
  lineChartDataTotalSpent[0].data = populateSalesChartData(lastSixMonthSales);
  lineChartDataTotalSpent[1].data = populateImpressionsChartData(lastSixMonthSales);

  

  // Chakra Color Mode

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  return (
    <Card
      w='100%'
      borderRadius='30px'
      {...rest}>
      <Flex justify='space-between' ps='0px' pe='20px' pt='5px' me='-100px'>
        <Flex w='100%'>
          <Text
            me='auto'>
          </Text>
          <Button
            bg={boxBg}
            fontSize='sm'
            fontWeight='500'
            color={textColorSecondary}
            borderRadius='7px'
            me='100px'
          >
            <Icon
              as={MdOutlineCalendarToday}
              color={textColorSecondary}
              me='4px'
            />
            Last six months
          </Button>
        </Flex>
      </Flex>
      <SimpleGrid
        width='120%'
        columns={{ base: 1, md: 1, lg: 2 }}>
        <Flex>
          <LineChart
            chartData={lineChartDataTotalSpent}
            chartOptions={lineChartOptionsTotalSpent}
          />
        </Flex>
        <Flex flexDirection='column' mt='55px' width='10%' ms='24%'>
          <MiniCardStatistics
            borderColor='#5c9a3c'
            value={`$${formatBigNumbers(totalSales)}`}
            name='Total Sales'
            i={`${formatBigNumbers(totalSales)}`}
          />
          <MiniCardStatistics
            borderColor='#a3f579'
            value={formatBigNumbers(totalImpressions)}
            name='Total Impressions'
            i={formatBigNumbers(totalImpressions)}
          />
        </Flex>
      </SimpleGrid>

    </Card>
  );
}
