import {
    Flex,
    Table,
    Progress,
    Icon,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { MdRemoveRedEye } from "react-icons/md";
import { Image } from '@chakra-ui/react'



// Assets
export default function Ranking(props) {
    const { columnsData, tableData } = props;

    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        initialState,
    } = tableInstance;
    initialState.pageSize = 5;

    const textColor = useColorModeValue("secondaryGray.900", "white");

    return (
        <Table {...getTableProps()} variant='simple' color='gray.500' mb='4px' width={'400px'}>
            <Thead>
                <Tr>
                    <Th>Views</Th>
                    <Th>Listing</Th>
                    <Th>Category</Th>
                </Tr>
            </Thead>
            <Tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                    prepareRow(row);
                    return (
                        <Tr {...row.getRowProps()} key={index}>
                            {row.cells.map((cell, index) => {
                                let data = "";
                                if (cell.column.Header === "VIEWS") {
                                    data = (
                                        <Flex align='left'>
                                            <Icon
                                                style={{ marginLeft: -1, alignSelf: 'center' }}
                                                as={MdRemoveRedEye}
                                                color='black'
                                                me='4px'
                                            />
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {cell.value}
                                            </Text>

                                        </Flex>
                                    );
                                } else if (cell.column.Header === "CATEGORY") {
                                    data = (
                                        <Flex align='left'>

                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {cell.value}
                                            </Text>
                                        </Flex>
                                    );
                                } else if (cell.column.Header === "LISTING TITLE") {
                                    data = (
                                        <Flex align='center'>

                                            <Text color={textColor} fontSize='sm' fontWeight='700' style={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>
                                                {cell.value}
                                            </Text>

                                        </Flex>
                                    );
                                }
                                return (
                                    <Td
                                        {...cell.getCellProps()}
                                        key={index}
                                        fontSize={{ sm: "14px" }}
                                        maxH='30px !important'
                                        py='8px'
                                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                        borderColor='transparent'>
                                        {data}
                                    </Td>
                                );
                            })}
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
}
