import React, { useEffect } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Flex,
    Image,
    Link,
    Text,
} from '@chakra-ui/react'
import { apiSlice } from "../../../data/api/apiSlice";
import { selectUser, useGetUserQuery, useGetUserStatsQuery } from "../../../data/slices/users/usersSlice";
import { useSelector } from "react-redux";

function PricingTable() {
    const { data: userDetails, isLoading: isLoadingUser, isSuccess: isSuccessUser, isError: isErrorUser, error: userError } = useGetUserQuery();

    // const { data, error, isLoading }  = apiSlice.endpoints.getUser.useQueryState("64066b4c988a1b123ef34c8f")

    useEffect(() => {
        console.log(userDetails)
      }, [userDetails]);
    // Paste the stripe-pricing-table snippet in your React component
    return (
        <stripe-pricing-table
            customer-email={userDetails ? userDetails.email : null}
            pricing-table-id={process.env.REACT_APP_STRIPE_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
        </stripe-pricing-table>
    );
}

export default PricingTable;