// Chakra imports
import {
  Box,
  Button,
  Icon,
  Text,
  useColorModeValue,
  Flex,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";


import Card from "components/card/Card.js";
// Custom components
import BarChart from "components/charts/BarChart";

import React, { useMemo } from "react";
import {
  barChartOptionsDailyTraffic,
  barChartDataDailyTraffic,
} from "variables/charts";
import { MdBarChart, MdOutlineCalendarToday } from "react-icons/md";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { RiEyeCloseLine } from "react-icons/ri";
import Ranking from './Ranking'
import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";


export default function WeeklyRevenue(props) {
  const { columnsData, tableData, trafficData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const { ...rest } = props;
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Card align='center' direction='column' w='100%' borderRadius='30px' overflow='hidden'>
      <Flex align='center' w='100%' px='15px' py='10px'>
        <Text
          me='auto'
          color={textColor}
          fontSize='xl'
          fontWeight='700'
          lineHeight='100%'>
          Top Listings
        </Text>
        <Flex justify='space-between' ps='0px' pe='20px' pt='5px' me='-100px'>
          <Text
            me='auto'>
          </Text>
          <Button
            bg={boxBg}
            fontSize='sm'
            fontWeight='500'
            color={textColorSecondary}
            borderRadius='7px'
            me='100px'
          >
            <Icon
              as={MdOutlineCalendarToday}
              color={textColorSecondary}
              me='4px'
            />
            This month
          </Button>
        </Flex>
      </Flex>
      <Flex>
        <Box h='240px' mt='auto'>
          <BarChart
            chartData={[{
              name: "School Impressions",
              data: trafficData,
            }]}
            chartOptions={barChartOptionsDailyTraffic}
          />
        </Box>
        <Ranking columnsData={columnsData} tableData={tableData} />
      </Flex>
      <Flex>

      </Flex>
    </Card>
  );
}
