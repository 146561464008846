import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdHome,
  MdAdd,
  MdNotifications,
  MdTask,
  MdHelp,
} from "react-icons/md";
import {
  FaListUl,
  FaServer,
  FaUsers,
  FaCode,
  FaTerminal,
  FaNetworkWired,
  FaPlug,
  FaHourglassStart
} from "react-icons/fa";


// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import Leads from "views/admin/leads";

import RTL from "views/admin/rtl";
import CreateListing from "views/admin/listing";
import Billing from "views/admin/billing";
import Listings from "views/admin/view";
import Support from "views/admin/support";
import Tasks from "views/admin/tasks";
import Notifications from "views/admin/notification";
import CRM from "views/admin/crm";

// Auth Imports


const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Submissions Status",
    layout: "/admin",
    icon: <Icon as={FaHourglassStart} width='20px' height='20px' color='inherit' />,
    path: "/status",
    component: DataTables,
  },
  {
    name: "Listings",
    layout: "/admin",
    path: "/listings",
    icon: <Icon as={FaListUl} width='20px' height='20px' color='inherit' />,
    component: Listings,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
  {
    name: "Create Listing",
    layout: "/admin",
    path: "/create",
    icon: <Icon as={MdAdd} width='20px' height='20px' color='inherit' />,
    component: CreateListing,
  },
  //{
  //  name: "Support",
  //  layout: "/admin",
  //  path: "/support",
  //  icon: <Icon as={MdHelp} width='20px' height='20px' color='inherit' />,
  //  component: Support,
  // },
  {
    name: "Tasks",
    layout: "/admin",
    path: "/tasks",
    icon: <Icon as={MdTask} width='20px' height='20px' color='inherit' />,
    component: Tasks,
  },
  // {
  //   name: "Notifications",
  //   layout: "/admin",
  //   path: "/notifications",
  //   icon: <Icon as={MdNotifications} width='20px' height='20px' color='inherit' />,
  //   component: Notifications,
  // },
  {
    name: "Leads",
    layout: "/admin",
    icon: <Icon as={FaUsers} width='20px' height='20px' color='inherit' />,
    path: "/customers",
    component: Leads,
  },
  {
    name: "CRM",
    layout: "/admin",
    icon: <Icon as={FaCode} width='20px' height='20px' color='inherit' />,
    path: "/crm",
    component: CRM,
  }
];

export default routes;
