// Chakra imports
import { Box, Flex, Icon, Text, Image, useBreakpointValue } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Footer from "components/footer/FooterAuth";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
// Custom components
import { NavLink } from "react-router-dom";
// Assets
import { FaChevronLeft } from "react-icons/fa";




function AuthIllustration(props) {
    const { children, illustrationBackground } = props;
    const illustrationDisplay = useBreakpointValue({
        base: "none",
        xl: "block",
    });
    // Chakra color mode
    return (
        <Flex position='relative' h='max-content'>
            <Flex
                h={{
                    sm: "initial",
                    md: "unset",
                    lg: "100vh",
                    xl: "97vh",
                }}
                w='100%'
                maxW={{ md: "66%", lg: "1313px" }}
                mx='auto'
                pt={{ sm: "50px", md: "0px" }}
                ps={{ xl: "0px" }}
                justifyContent='start'
                direction='column'>
                <NavLink
                    to='/admin'
                    style={() => ({
                        width: "fit-content",
                        marginTop: "40px",
                    })}>
                    <Flex
                        align='center'
                        ps={{ base: "25px", lg: "0px" }}
                        pt={{ lg: "0px", xl: "0px" }}
                        w='fit-content'>
                        <Icon
                            as={FaChevronLeft}
                            me='12px'
                            h='13px'
                            w='8px'
                            color='#141d28'
                        />
                        <Text ms='0px' fontSize='sm' color='black'>
                            Go Back
                        </Text>
                    </Flex>
                </NavLink>
                <Flex
                    direction={{ base: "row", xl: "row" }}
                    align={{ base: "center", xl: "stretch" }}
                    justifyContent={{ base: "center", xl: "start" }}
                    alignItems="center"
                >

                    <Box flex={{ base: "none", xl: "1" }} mr={{ base: "0px", xl: "32px" }}>
                        <Flex justifyContent='center' mt='auto'>

                            {children}
                        </Flex>

                    </Box>
                </Flex>
                <Flex justifyContent='center' mt='auto'>
                    <Footer />
                </Flex>
            </Flex>
        </Flex>
    );
}
// PROPS

AuthIllustration.propTypes = {
    illustrationBackground: PropTypes.string,
    image: PropTypes.any,
};

export default AuthIllustration;
