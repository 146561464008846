import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Flex,
    Image,
    Link,
    Text,
    Box
} from '@chakra-ui/react'
import PricingTable from 'views/admin/stripe/PricingTable';

export default function StripeModalPopup(props) {
    const { isOpen, onOpen, onClose } = props
    return (
        <Modal isOpen={isOpen} onClose={onClose} mr={3} size="full">
            <ModalOverlay />
            <ModalContent
                mt={20}
                mb={250}
                mx={[1, 'auto']}
                h='20px'
                w='1400px'
                rounded="lg" bg="#141d28">
                {/* <ModalHeader>Modal Title</ModalHeader> */}
                <ModalCloseButton color={"#95dd6e"} />
                <Box  m={4}>
                    <PricingTable />
                </Box>
                {/* <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost'>Secondary Action</Button>
                    </ModalFooter> */}
            </ModalContent>
        </Modal>
    )
}