import React from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HorizonLogo, PremiumLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand({ isPremiumAccount, isFreeTrial}) {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
      {isPremiumAccount && !isFreeTrial ?
        <PremiumLogo h='130px' w='875px' my='0px' mb='-20px' ml='15px' color={logoColor} /> :
        <HorizonLogo h='130px' w='875px' my='0px' mb='-20px' ml='15px' color={logoColor} />
      }
      <HSeparator mb='15px' />
    </Flex>
  );
}

export default SidebarBrand;
