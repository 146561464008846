import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  useColorModeValue,
  useDisclosure, Text, Spinner,
  UnorderedList, ListItem, List
} from '@chakra-ui/react';
import FormsWrapper from "./FormsWrapper"
import { useEffect, useState } from "react";
import { useGetListingQuery } from "../../../data/slices/listings/listingsslice";

export default function EditModal(props) {
  const { isOpen, onOpen, onClose, listingId, handleDeleteListing, isDeleting, isPlanEligibleToPostToAllSchools} = props;
  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetListingQuery(listingId, {
    enabled: isOpen
  });
  const textColor = useColorModeValue("secondaryGray.900", "white");
  useEffect(() => {

  }, [listingId])
  const handleCloseModal = () => {
    // Call the `refetch` function to invalidate the query and refetch data when the modal closes
    onClose();
  };
  if (isLoading || (listingId == null)) {
    return (
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="full" mt="50px" mb={5}>
        <ModalOverlay />
        <ModalContent
          mx={['10px', '50px']} // responsive margin using an array of values
          bg='white'
          maxW='100%' // set a maximum width
          borderRadius={'25px'}
          h={['auto', '860px']} // responsive height using an array of values
        >
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            height='100vh'
          >
            <Spinner size='xl' />
          </Box>
        </ModalContent>
      </Modal>
    )
  }

  if (isError) {
    return (
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="full" mt="50px" mb={5}>
        <ModalOverlay />
        <ModalContent
          mx={['10px', '50px']} // responsive margin using an array of values
          bg='white'
          maxW='100%' // set a maximum width
          borderRadius={'25px'}
          h={['auto', '860px']} // responsive height using an array of values
        >
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            height='100vh'
          >      <Text
            color={textColor}
            fontSize='34px'
            fontWeight='700'
            lineHeight='100%'>Error: {error.status}</Text>
          </Box>
        </ModalContent>
      </Modal>)
  }
  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} size="full" mt="50px" mb={5}>
      <ModalOverlay />
      <ModalContent
        mx={['10px', '50px']} // responsive margin using an array of values
        color='white' bg='white'
        maxW='100%' // set a maximum width
        borderRadius={'25px'}
        overflowY='auto' // responsive height using an array of values
      >
        {data && data.status.toUpperCase() == "REJECTED" ?
          <ModalHeader fontSize='xl'
            fontWeight='small'
            bg='#DE6657'
            borderTopRightRadius={'25px'}
            borderTopLeftRadius={'25px'}
            align='center'
          >
            <Text>This listing doesn't meet our rules. Please update this listing, you can contact us with any questions.</Text>
            <List listStyleType="disc" ml={4}>
              {data.comments.map((comment, index) => (
                <ListItem key={index}>{comment}</ListItem>
              ))}
            </List>
          </ModalHeader> : null}
        {data && data.status.toUpperCase() == "CHANGES REQUIRED" ?
          <ModalHeader fontSize='xl'
            fontWeight='small'
            bg='orange.500'
            borderTopRightRadius={'25px'}
            borderTopLeftRadius={'25px'}
            align='center'
          >
            <Box>
              <Text>Changes required, please fix comments below and resubmit:</Text>
              <List listStyleType="disc" ml={4}>
                {data.comments.map((comment, index) => (
                  <ListItem key={index}>{comment}</ListItem>
                ))}
              </List>
            </Box>
          </ModalHeader> : null}
        <ModalCloseButton mt='9px' color='black' />
        <ModalBody>
          <FormsWrapper data={data} closeModal={handleCloseModal} handleDeleteListing={handleDeleteListing} isDeleting={isDeleting} isPlanEligibleToPostToAllSchools={isPlanEligibleToPostToAllSchools} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
