import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authenticated: (Cookies.get('SOUK_TOKEN') != null),
  },
  reducers: {
    setAuthenticated: (state, action) => {
      state.authenticated = action.payload;
    },
  },
});

export const { setAuthenticated } = authSlice.actions;

export const selectAuthenticated = state => state.auth.authenticated;

export default authSlice.reducer;
