export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];
export const columnsDataComplex = [
  {
    Header: "VIEWS",
    accessor: "schoolImpressions",
  },
  {
    Header: "SCHOOL",
    accessor: "schoolLogo",
  },
  {
    Header: "LISTING TITLE",
    accessor: "listingTitle",
  },
  {
    Header: "CATEGORY",
    accessor: "category",
  },
];
