import { useState } from 'react'
import {
    useLocation
} from "react-router-dom"
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Text,
    AlertIcon,
    Alert,
    Flex
} from '@chakra-ui/react'
import DefaultAuth from "layouts/auth/ResetAuth";
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useResetPasswordMutation } from '../../../data/slices/auth/authApiSlice'

function ResetPassword() {
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    const token = queryParameters.get('token');
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)
    const [resetPassword, { isLoading }] = useResetPasswordMutation()

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!token) {
            setError('Invalid token. Please click on the link provided via email or contact support@collegesouk.com');
            return;
        }
        if (password !== confirmPassword) {
            setError('Passwords do not match.')
            return
        }
        if (password && password.length < 6) {
            setError("Password is too short (minimum is 6 characters)");
            return;
        }
        try {
            await resetPassword({ newPassword: password, token: token }).unwrap()
            setSuccess(true)
            setError(null)
        } catch (err) {
            setError(err.message)
        }
    }

    return (
        <DefaultAuth align='center'>
            <Box
                p={[10]}
                mt={[1000, '2vh']}
                direction='column'
                w='500px'
                h='700px'
                maxW='100%'
                borderRadius='15px'
            >
                {error ?
                    <Flex align='center' mb='25px'>
                        <Alert status='error'>
                            <AlertIcon />
                            {error}
                        </Alert>
                    </Flex> : null}
                <Heading color={'white'} mb={6}>
                    Reset Password
                </Heading>
                {!success ? (
                    <Box as='form' onSubmit={handleSubmit}>
                        <FormControl id='password' isRequired isInvalid={error}>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='md'
                                fontFamily={'arial'}
                                fontWeight='light'
                                color='black'
                                mb='2px'
                            >
                                New Password
                            </FormLabel>
                            <Input
                                id='password'
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                                isRequired={true}
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: '0px', md: '0px' }}
                                type='password'
                                placeholder='Enter new password'
                                mb='10px'
                                fontWeight='500'
                                size='lg'
                                bg='white'
                            />
                        </FormControl>
                        <FormControl id='confirmPassword' isRequired isInvalid={error}>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='md'
                                fontFamily={'arial'}
                                fontWeight='light'
                                color='black'
                                mb='2px'
                            >
                                Confirm Password
                            </FormLabel>
                            <Input
                                id='confirmPassword'
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value)
                                }}
                                isRequired={true}
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: '0px', md: '0px' }}
                                type='password'
                                placeholder='Confirm new password'
                                mb='10px'
                                fontWeight='500'
                                size='lg'
                                bg='white'
                            />
                        </FormControl>
                        <Button
                            fontSize='lg'
                            fontWeight='semibold'
                            variant='brand'
                            color='black'
                            w='250px'
                            h='50'
                            mb='24px'
                            mt='20px'
                            isLoading={isLoading}
                            type="submit"
                            background='#A3F579'
                            ms='80px'
                            boxShadow='0px 4px 4px rgba(0, 0, 0, 0.15)'
                            borderRadius='50px'
                            // on actions
                            _hover={{ background: '#95dd6e' }}>
                            Reset Password
                        </Button>
                    </Box>
                ) : (

                    <Box display="flex" alignItems="center">
                        <Text alignItems="center">
                            Your password has been updated, sign in to access your account!
                            <CheckCircleIcon color="green.500" ml={2} mr={2} />
                        </Text>
                    </Box>

                )}
            </Box>

        </DefaultAuth >
    )
}

export default ResetPassword
