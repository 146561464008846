import React from "react";
// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignUpCentered from "views/auth/signUp";
import ForgotPassword from "views/auth/forgotPassword";
import ResetPassword from "views/auth/resetPassword";


const routes = [
  {
    name: "Signin",
    layout: "/auth",
    path: "/sign-in",
    // icon: <Icon as={MdNotifications} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
  {
    name: "Signup",
    layout: "/auth",
    path: "/sign-up",
    component: SignUpCentered,
  },
  {
    name: "ForgotPassword",
    layout: "/auth",
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    name: "ResetPassword",
    layout: "/auth",
    path: "/reset-password",
    component: ResetPassword,
  }
];

export default routes;
