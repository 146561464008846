import {
  Button,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import logo from "assets/img/layout/logo.png";
import React, { useEffect } from "react";
import StripeModalPopup from "./StripeModalPopup"

export default function SidebarDocs({ isPremiumAccount, subDetails, isFreeTrial }) {
  const bgColor = "#141d28";
  const borderColor = useColorModeValue("white", "navy.800");
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Flex
      justify='center'
      direction='column'
      align='center'
      bg={bgColor}
      borderRadius='30px'
      me='20px'
      position='relative'>
      <Flex
        border='5px solid'
        borderColor={borderColor}
        bg='#141d28'
        borderRadius='50%'
        w='94px'
        h='94px'
        align='center'
        justify='center'
        mx='auto'
        position='absolute'
        left='50%'
        top='-47px'
        transform='translate(-50%, 0%)'>
        <Image src={logo} w='58px' h='58px' />
      </Flex>

      <Flex
        direction='column'
        mb='12px'
        align='center'
        justify='center'
        px='15px'
        pt='55px'>
        <Text
          fontSize='14px'
          color={"white"}
          px='10px'
          mb='7px'
          textAlign='center'>
          {null}
        </Text>
      </Flex>
      <Flex
        direction='row'
        align='center'
        justify='center'
        mx='auto'
        position='absolute'
      >
        <StripeModalPopup
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      </Flex>

      {!isPremiumAccount || isFreeTrial ? <Button
        bg='whiteAlpha.300'
        _hover={{ bg: "whiteAlpha.200" }}
        _active={{ bg: "whiteAlpha.100" }}
        mb={{ sm: "16px", xl: "24px" }}
        color={"white"}
        fontWeight='bold'
        fontSize='sm'
        minW='185px'
        mx='auto'
        borderRadius={"30px"}
        onClick={onOpen}
      >
        Upgrade Premium
      </Button> : <Button
        bg='#FFD700'
        _hover={{ bg: "#CCB300" }}
        _active={{ bg: "whiteAlpha.100" }}
        mb={{ sm: "16px", xl: "24px" }}
        color={bgColor}
        fontWeight='bold'
        fontSize='sm'
        minW='185px'
        mx='auto'
        borderRadius={"30px"}
      //onClick={onOpen}
      >
        Premium
      </Button>}
    </Flex>
  );
}
