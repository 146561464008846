export const columnsDataCheck = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Time",
    accessor: "time",
  },
  {
    Header: "Time remaining",
    accessor: "timeremaining",
  },
  {
    Header: "Notes",
    accessor: "notes",
  },
];