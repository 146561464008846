// chakra imports
import { Box, Flex, Stack, Spinner } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import SidebarCard from "components/sidebar/components/SidebarCard";
import React, { useEffect } from "react";
import { useGetUserQuery, selectUser } from "../../../data/slices/users/usersSlice";

import { useGetSubDetailsQuery } from "../../../data/slices/subscription/subscription";
import { isPremium, isFreeTrial } from "../../../util/util";
import { useSelector } from "react-redux";

// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;
  // SIDEBAR
  const user = useSelector(selectUser);
  const { data: userDetails, isLoading: isLoadingUser, isSuccess: isSuccessUser, isError: isErrorUser, error: userError } = useGetUserQuery();
  const { data: subDetails, error, isLoading } = useGetSubDetailsQuery(userDetails?.email ?? "");

  const isPremiumAccount = isPremium(subDetails);
  const freeTrial = isFreeTrial(subDetails);

  // useEffect(() => {
  //   console.log(user)
  // }, [user]);
  if (isLoading || isLoadingUser) {
    return (<Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      height='100vh'
    >
      <Spinner size='xl' />
    </Box>)
  }

  return (
    <Flex direction='column' height='100%' pt='25px' borderRadius='30px'>
      <Brand isFreeTrial={freeTrial} isPremiumAccount={isPremiumAccount} subDetails={subDetails} />
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes} />
        </Box>
      </Stack>

      <Box
        ps='20px'
        pe={{ md: "16px", "2xl": "0px" }}
        mt='60px'
        mb='40px'
        borderRadius='30px'>
        <SidebarCard isPremiumAccount={isPremiumAccount} isFreeTrial={freeTrial} subDetails={subDetails} />
      </Box>
    </Flex>
  );
}

export default SidebarContent;
